import React, { useState } from 'react'
import { IncludeBatchAssignment } from './IncludeBatchAssignment'
import { FormProvider } from 'contexts/formContext'
import { includeBatchAssingmentDefaultValues, includeBatchAssingmentSchema } from './IncludeBatchAssignmentSchema'
import { useFundsList } from 'contexts/fundContext'
import { Stack } from '@mui/material'
import { IncludeBatchAssignmentHeader } from './IncludeBatchAssignmentHeader'
import { FieldValues } from 'react-hook-form'
import { toastState, ToastType } from 'contexts/apiRequestContext'
import Toast from 'components/Toast/Toast'
import { Error } from 'components/Errors/Error'
import { useListCreditNotes } from 'contexts/batchAssignment/batchAssignmentContext'
import { CreditNotes } from './CreditNotes/CreditNotes'
import { FiltersBatchAssigmentParametersProps } from 'services/creditNote/BatchAssignment/BatchAssignment.types'
import { RefreshProgress } from 'components/RefreshProgress'
import { GridRowId, GridSelectionModel } from '@mui/x-data-grid'
import { CreditNotesHeader } from './CreditNotes/CreditNotesHeader'
import { Drawer } from '@uy3/web-components'
import { CreateBatchAssignmentContainer } from './CreateBatchAssignment/CreateBatchAssignmentContainer'

export const IncludeBatchAssignmentContainer = () => {
    const { fundAutoCompleteProps } = useFundsList({ page: 0, size: 10 }, 'always');
    
    const [rowsSeelcted, setRowsSelected] = useState<string[] | GridRowId[]>();
    const [action, setAction] = useState<string | undefined>(undefined);

    const [toast, setToast] = useState<ToastType>(toastState);

    const [parameters, setParameters] = useState<FiltersBatchAssigmentParametersProps | undefined>(undefined);

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const onChangePage = (page: number) => {
        setPage(page);
        setParameters({ ...parameters, page });
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setParameters({ ...parameters, size: page });
        setPage(0);
    };

    const { data: queryData, isLoading, isFetching, isSuccess } = useListCreditNotes({
        ...parameters!
    });

    const onSubmit = async (values: FieldValues) => {
        const filters = Object.fromEntries(
            Object.entries(values ?? {})
                .filter(([_, v]) => v != null && v !== undefined)
        );
        setParameters({
            ...filters,
            page,
            size: rowsPerPage
        });
    };

    if (queryData?.type === 'error') {
        return <Error error="Houve um erro ao obter os dados da operação" />
    }

    const handleSelectionModelChange = (selectionModel: GridSelectionModel) => setRowsSelected(selectionModel);

    const showListCreditNotes = Object.values(parameters ?? {}).length > 0 && isSuccess;

    return (
        <Stack spacing={2} mt={-3} mb={15}>
            <Toast toast={toast} setToast={setToast} />

            <FormProvider
                defaultValues={includeBatchAssingmentDefaultValues}
                validationSchema={includeBatchAssingmentSchema()}
                onSubmit={onSubmit}
            >
                <>
                    <IncludeBatchAssignmentHeader />
                    <IncludeBatchAssignment
                        fundAutocomplete={fundAutoCompleteProps}
                    />
                </>
            </FormProvider>
            <RefreshProgress refreshing={(isLoading || isFetching) && parameters !== undefined} />
            {showListCreditNotes && (
                <Stack>
                    <CreditNotesHeader
                        generateBatchAssignment={() => setAction('createBatchAssignment')}
                    />
                    <CreditNotes
                        queryData={queryData!}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={onChangeRowsPerPage}
                        setPage={onChangePage}
                        handleSelectionModelChange={handleSelectionModelChange}
                        rowsSelected={rowsSeelcted}
                    />
                </Stack>
            )}
            <Drawer {...{
                anchor: 'right',
                open: action === 'createBatchAssignment',
                title: 'Gerar cobrança',
                children: <CreateBatchAssignmentContainer
                    onClose={() => setAction(undefined)}
                    parameters={parameters!}
                    setToast={setToast}
                />
            }} />
        </Stack>
    )
}
