/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, InputAdornment, Grid, Stack } from '@mui/material';
import { SearchIcon } from '@uy3/web-components';
import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { useFormContext } from 'contexts/formContext';
import { useTenant } from 'contexts/tenantContext';
import { CurrentValuesSelected } from '../CurrentValuesSelected/CurrentValuesSelected';
import { FilterStyleBase } from '../MethodsForFilters/FilterStyleBase';
import { creditNoteSelectFilterOptions } from 'contexts/creditNote/creditNoteOptions';
import { sendSubmitEvent } from 'contexts/apiRequestContext';
import Group, { useGroupListData } from 'contexts/groupContext';
import { PersonFull, usePersonsList } from 'contexts/personContext';
import { useEnumContext } from 'contexts/enumContext';
import { sortToSelectOptionList } from 'helpers/formats/ArrayFormats';
import { categoryNameOptions } from 'services/creditProduct/types/creditProductReadModel';
import { filterByOperationOptions, liquidationModeOptions } from 'services/creditNote/options';

type CreditNoteFilterProps = {
    currentValues: any;
    removeFilter: (index: number, duplicateRemove?: boolean) => void;
};

const CreditNoteFilterForm = () => {
    const { watch } = useFormContext();
    const { tenantAutoCompleteProps, currentTenantId } = useTenant();
    const { data: typeOfRelationship } = useEnumContext({ enumName: 'TypeOfRelationship', size: 50 });
    const { groupAutoCompleteProps, data } = useGroupListData({ page: 0, size: 10 });
    const { personAutoCompleteProps, data: personData } = usePersonsList({ page: 0, size: 10, tenant: currentTenantId }, 'always');

    let groupAutocomplete: any = {
        ...groupAutoCompleteProps,
        listOptions:
            data?.data?.map((i: Group) => {
                return { label: i.groupName, value: i.groupName };
            }) || [],
    };

    let dataPerson = personData as any;
    const personAutocompleteOptions: any = {
        ...personAutoCompleteProps,
        listOptions:
            dataPerson?.data?.map((person: PersonFull) => {
                return { label: person.name, value: person.id };
            }) || [],
    };

    return (
        <>
            <SelectFormField
                name="optionFilter"
                label="Filtrar por"
                variant="outlined"
                fullWidth
                options={filterByOperationOptions}
            />

            {watch('optionFilter') === 'liquidationMode' && (
                <SelectFormField
                    label="Filtrar por modo de liquidação"
                    name="liquidationMode"
                    fullWidth
                    showEndAdornment
                    options={liquidationModeOptions}
                    onClick={sendSubmitEvent}
                />
            )}

            {watch('optionFilter') === 'filterByOwnerGroup' && (
                <AutocompleteField
                    label="Filtrar por grupos"
                    name="filterByOwnerGroup"
                    showEndIcon
                    endIconType="submit"
                    displayName="filterByOwnerGroupDisplay"
                    {...groupAutocomplete}
                />
            )}
            {watch('optionFilter') === 'searchString' && (
                <TextFormField
                    name="searchString"
                    variant="outlined"
                    label="Filtrar por todos"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit" id="button-filter-all">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'tenant' && (
                <AutocompleteField
                    label="Filtrar por correspondente"
                    name="tenant"
                    displayName="tenantDisplay"
                    showEndIcon
                    endIconType="submit"
                    {...tenantAutoCompleteProps}
                />
            )}
            {watch('optionFilter') === 'personId' && (
                <AutocompleteField
                    label="Filtrar por tomador"
                    name="personId"
                    displayName="personDisplay"
                    showEndIcon
                    endIconType="submit"
                    {...personAutocompleteOptions}
                />
            )}

            {watch('optionFilter') === 'productCategoryDisplay' && (
                <SelectFormField
                    name="productCategoryDisplay"
                    label="Categoria do produto"
                    showEndAdornment
                    fullWidth
                    options={categoryNameOptions}
                    onClick={sendSubmitEvent}
                />
            )}

            {watch('optionFilter') === 'ownLiquidation' && (
                <SelectFormField
                    name="ownLiquidation"
                    label="Liquidação Própria"
                    showEndAdornment
                    fullWidth
                    options={[
                        { label: 'Sim', value: true },
                        { label: 'Não', value: false },
                    ]}
                    onClick={sendSubmitEvent}
                />
            )}
            {watch('optionFilter') === 'ownCompliance' && (
                <SelectFormField
                    name="ownCompliance"
                    label="Aprovação Compliance própria"
                    showEndAdornment
                    fullWidth
                    options={[
                        { label: 'Sim', value: true },
                        { label: 'Não', value: false },
                    ]}
                    onClick={sendSubmitEvent}
                />
            )}

            {watch('optionFilter') === 'creditNoteNo' && (
                <TextFormField
                    label="Filtrar por número da operação"
                    name="creditNoteNo"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" sx={{ marginRight: -5 }}>
                                <span
                                    style={{
                                        display: 'block',
                                        fontSize: '20px',
                                    }}
                                >
                                    #
                                </span>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    type="number"
                />
            )}
            {watch('optionFilter') === 'relatedPersons' && (
                <Stack
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'auto auto 3%',
                        alignItems: 'center',
                        gap: 10,
                    }}
                >
                    <Grid>
                        <AutocompleteField
                            name="relatedPersons"
                            label="Envolvido"
                            displayName="relatedPersonDisplay"
                            required
                            {...personAutocompleteOptions!}
                        />
                    </Grid>
                    <Grid>
                        <SelectFormField
                            name="typeOfRelationship"
                            label="Tipo de relação"
                            variant="outlined"
                            options={sortToSelectOptionList(typeOfRelationship ?? [])}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid>
                        <IconButton type="submit">
                            <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                        </IconButton>
                    </Grid>
                </Stack>
            )}
            {watch('optionFilter') === 'status' && (
                <SelectFormField
                    label="Filtrar por status"
                    name="status"
                    fullWidth
                    showEndAdornment
                    options={creditNoteSelectFilterOptions}
                    onClick={sendSubmitEvent}
                />
            )}
            {watch('optionFilter') === 'maxValue' && (
                <CurrencyFormField
                    fullWidth
                    label="Valor máximo"
                    name="maxValue"
                    variant="outlined"
                    required
                    showEndAdornment
                />
            )}
            {watch('optionFilter') === 'minValue' && (
                <CurrencyFormField
                    fullWidth
                    label="Valor mínimo"
                    name="minValue"
                    variant="outlined"
                    required
                    showEndAdornment
                />
            )}
            {watch('optionFilter') === 'initialDate' && (
                <Grid
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        gridTemplateColumns: 'auto auto',
                        gridGap: 10,
                    }}
                >
                    <DatePickerFormField
                        label="Filtrar por data inicial"
                        name="initialDate"
                        fullWidth
                        required
                    />
                    <DatePickerFormField
                        label="Filtrar por data final"
                        name="finalDate"
                        fullWidth
                        required
                        componentFilter={
                            <InputAdornment position="end" sx={{ marginRight: -1.3, ml: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Grid>
            )}
            {watch('optionFilter') === 'initialPaymentDate' && (
                <Grid
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        gridTemplateColumns: 'auto auto',
                        gridGap: 10,
                    }}
                >
                    <DatePickerFormField
                        label="Data inicial do pagamento"
                        name="initialPaymentDate"
                        fullWidth
                        required
                    />
                    <DatePickerFormField
                        label="Data final do pagamento"
                        name="finalPaymentDate"
                        fullWidth
                        required
                        componentFilter={
                            <InputAdornment position="end" sx={{ marginRight: -1.3, ml: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Grid>
            )}
        </>
    );
};

export const CreditNoteFilter = ({ currentValues, removeFilter }: CreditNoteFilterProps) => {
    return (
        <FilterStyleBase
            formFilters={<CreditNoteFilterForm />}
            currentValues={
                <CurrentValuesSelected
                    enableSelectFilter
                    recordType="credit-note-columns"
                    currentValues={currentValues}
                    removeFilterSelected={removeFilter}
                />
            }
        />
    );
};
