import { FormMode, FormProvider } from 'contexts/formContext';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ApiResponseError,
  ToastType,
  showErrorToast,
  showSuccessToast,
  showWarningToast,
  toastState,
  useApiRequest
} from 'contexts/apiRequestContext';
import { RefreshProgress } from 'components/RefreshProgress';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import React, { useState } from 'react';
import BankAccountForm from './BankAccountRequestForm';
import {
  BankAccountRequestFormSchema,
  defaultValuesBankAccountRequestForm,
  validationSchemaBankAccountRequestForm,
} from './BankAccountRequestSchema';
import {
  useBankAccountMutation,
  useBankAccountRequestData,
} from 'contexts/bankAccount/bankAccountRequest';
import { useIdentity } from 'contexts/identityContext';
import { Modal, Popup } from '@uy3/web-components';
import ApproveAndDisapproveFormContainer from '../BankAccountRequestList/ApproveAndDisapproveForm/ApproveAndDisapproveFormContainer';
import Toast from 'components/Toast/Toast';
import ManualUpdateForm from 'pages/CreditNote/CreditNoteForm/Modals/ManualUpdate/ManualUpdateForm';
import {
  defaultValuesManualUpdateForm,
  validationSchemaManualUpdateForm,
} from 'pages/CreditNote/CreditNoteForm/Modals/ManualUpdate/ManualUpdateSchema';
import { FieldValues } from 'react-hook-form';
import { sendWebCredCreditNote } from 'services/creditNote';
import { useTenant } from 'contexts/tenantContext';
import { useUserPermissionData } from 'contexts/userContext';
import {
  OperatorReadModel,
  BankAccountRequestCreateOrUpdate,
  UpdateDocumentsProps,
} from 'services/accounts/bankAccountRequest/bankAccountRequest.types';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { DeleteCheckListContainer } from 'pages/CreditNote/CreditNoteForm/Modals/DeleteCheckList/DeleteCheckListContainer';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import AssignDrawer from 'components/Assign/AssignDrawer';
import {
  approvalRevisionById,
  deleteBankAccountRequestById,
  manualUpdateBankAccountRequest,
  postBankAccountRequestSubmitApproval,
  postOpenBankAccountRequest,
  updateDocumentsBankAccountRequest,
} from 'services/accounts/bankAccountRequest/bankAccountRequest';
import { GenericActionHandler } from 'components/GenericActionHandler/GenericActionHandler';
import { GenericFormSkeleton } from 'components/Skeleton/GenericFormSkeleton';
import GenericErrorBoundary from 'components/Errors/ErrorBoundary/GenericErrorBoundary';
import { PopupState } from 'contexts/creditNote/creditNoteActions.interface';
import { ApproveInstrument } from './ButtonActions/ApprovalsInstrument/ApproveInstrument';
import { RejectInstrument } from './ButtonActions/ApprovalsInstrument/RejectInstrument';
import { ApprovalsSignatureForm } from './ButtonActions/ApprovalsSignature/ApprovalsSignatureForm';
import { DraftPreviewContainer } from './ButtonActions/DraftPreview/DraftPreviewContainer';
import { CancelBankAccountRequestContainer } from './ButtonActions/CancelBankAccountRequest/CancelBankAccountRequestContainer';

type OptionsActionsType =
  | 'approve'
  | 'disapprove'
  | 'assign'
  | 'delete'
  | 'update'
  | 'deleteCheckList'
  | 'approveSignature'
  | 'disapproveSignature'
  | 'draftpreview'
  | undefined;

export const BankAccountRequestFormContainer = () => {
  const { bankAccountId } = useParams();
  const { token } = useIdentity();
  const { currentTenantId } = useTenant();
  const { data: permissionsData, hasPermission } = useUserPermissionData();
  const formMode: FormMode = bankAccountId !== 'nova' ? 'update' : 'create';
  const [handleAction, setHandleAction] = useState<OptionsActionsType>(undefined);
  const { setFormFieldsErrors } = useFormFieldsError();
  const { submitting, endRequest, startRequest } = useApiRequest();
  const [error, setError] = useState<ApiResponseError | undefined>(undefined);
  const navigate = useNavigate();
  const [toast, setToast] = useState<ToastType>(toastState);
  const typePermission = bankAccountId === 'nova' ? 'Create' : 'Update';
  const hasPermissionCreateOrEditbyParams = hasPermission('BankAccountRequest', typePermission);
  const [popupProps, setPopupProps] = useState<PopupState | undefined>(undefined);

  const {
    bankAccountRequestData,
    bankAccountRequestErr,
    bankAccountRequestStatus,
    refetch,
    isLoading,
  } = useBankAccountRequestData(bankAccountId!);

  const handleErrorResponse = (error: ApiResponseError) => {
    endRequest(false);
    const { errorMessage } = mapErrorResponse(error);
    const title = 'Ops, ocorreu um erro!';
    const description = errorMessage;
    showErrorToast(title, description, setToast);
  };

  const onSuccess = (data: BankAccountRequestCreateOrUpdate) => {
    navigate(`/contas-digitais/solicitacoes/${formMode === 'create' ? data : data.id ?? bankAccountId}`);
    setFormFieldsErrors([]);
    setError(undefined);
    const title = formMode === 'create' ? "Solicitação de conta criada com sucesso!" : "Solicitação de conta atualizada com sucesso!";
    const description = undefined;
    showSuccessToast(title, description, setToast);
  };

  const onError = (error: ApiResponseError) => {
    const { fieldErrors, warningToastError, errorMessage } = mapErrorResponse(error);
    setError(error);
    setFormFieldsErrors(fieldErrors ?? []);

    if (warningToastError && warningToastError?.length > 0) {
      const title = 'Atenção!';
      showWarningToast(title, warningToastError, setToast);
    }
    else {
      const title = 'Ops, ocorreu um erro!';
      const description = errorMessage;
      showErrorToast(title, description, setToast);
    }

  };

  const onClose = () => setHandleAction(undefined);

  const { mutateAsync } = useBankAccountMutation(bankAccountId!, onSuccess, onError);
  const onSubmit = async (values: BankAccountRequestFormSchema) => {

    let operators: OperatorReadModel[] = values?.operators;
    if (operators) {
      //@ts-ignore
      values.operators = operators.map((operator) => {
        return {
          ...operator,
          tenantName: operator?.tenant,
        };
      });
    }

    let payload: any = {
      ...values,
      uploads: values?.uploads || [],
      relatedPerson: values?.relatedPerson || []
    };

    await mutateAsync(payload);
  };

  const submitApprovalBankAccountRequest = () => {
    startRequest();
    postBankAccountRequestSubmitApproval(bankAccountId!, token!)
      .then(() => {
        navigate(`/contas-digitais/solicitacoes`);
        endRequest(true);
        const title = 'Conta enviada para aprovação com sucesso!';
        const description = undefined;
        showSuccessToast(title, description, setToast);
      })
      .catch((response: ApiResponseError) => handleErrorResponse(response));
  };

  const deletedBankAccountRequest = async () => {
    startRequest();
    await deleteBankAccountRequestById(bankAccountId!, token!)
      .then(() => {
        navigate(`/contas-digitais/solicitacoes`);
        endRequest(true);
        const title = 'Solicitação de conta excluída com sucesso!';
        const description = undefined;
        showSuccessToast(title, description, setToast);
      })
      .catch((response: ApiResponseError) => handleErrorResponse(response));
  };

  const onSubmitManualUpdate = async (values: FieldValues) => {
    const {
      newStatus,
      timelineAction,
      newTimelineType,
      newTimelineDescription,
      workflowAction,
      sendWebCred,
    } = values;

    let formValues = {
      newStatus,
      timelineAction,
      newTimelineType,
      newTimelineDescription,
      workflowAction,
    };
    startRequest();

    if (sendWebCred) {
      await sendWebCredCreditNote(bankAccountId!, token!, currentTenantId).catch(
        (response: ApiResponseError) => handleErrorResponse(response)
      );
    }

    await manualUpdateBankAccountRequest(bankAccountId!, formValues, token!, currentTenantId)
      .then(() => {
        endRequest(true);
        setHandleAction(undefined);
        refetch();
        const title = 'Status da conta alterado com sucesso!';
        const description = undefined;
        showSuccessToast(title, description, setToast);
      })
      .catch((response: ApiResponseError) => handleErrorResponse(response));
  };

  const onSendApprovalRevision = async () => {
    startRequest();
    await approvalRevisionById(bankAccountId!, token!)
      .then(() => {
        endRequest(true);
        navigate('/contas-digitais/solicitacoes/');
        refetch();
        const title = 'Assinatura concluída com sucesso!';
        const description = undefined;
        showSuccessToast(title, description, setToast);
      })
      .catch((response: ApiResponseError) => handleErrorResponse(response));
  };

  async function openBankAccountRequest() {
    startRequest();
    await postOpenBankAccountRequest(bankAccountId!, token!)
      .then(() => {
        endRequest(true);
        const title = 'Conta registrada com sucesso!';
        const description = undefined;
        showSuccessToast(title, description, setToast);
        refetch();
      })
      .catch((response: ApiResponseError) => handleErrorResponse(response));
  }

  // const onAssignBankAccountRequest = (formValues: any) => {
  //   startRequest();
  //   let payload = {
  //     tenant: currentTenantId!,
  //     groupName: formValues?.groupName,
  //     userId: formValues?.userId
  //   };
  //   postAssignBankAccountRequest(bankAccountId!, payload, token!)
  //     .then(() => {
  //       onClose();
  //       endRequest(true);
  //       setToast({
  //         open: true,
  //         title: "Sucesso ao atribuir a solicitação de conta",
  //         severity: "success"
  //       });
  //       navigate("/contas-digitais/solicitacoes");
  //     }).catch((response: ApiResponseError) => handleErrorResponse(response));
  // };

  const onUploadDocs = async (uploads: []) => {
    startRequest();
    const payload = {
      uploads: uploads,
    } as UpdateDocumentsProps;

    await updateDocumentsBankAccountRequest(bankAccountId!, payload, token!)
      .then(() => {
        onClose();
        endRequest(true);
        const title = 'Documentos atualizados com sucesso!';
        const description = undefined;
        showSuccessToast(title, description, setToast);
      })
      .catch((response: ApiResponseError) => handleErrorResponse(response));
  };

  const isReadOnly = (): boolean => {
    let status: string[] = ['Draft', 'Error', 'Revision', 'Disapproved', 'CorbanPreAnalysis'];
    if ((formMode === 'update' && !status.includes(bankAccountRequestData?.status)) || !hasPermissionCreateOrEditbyParams) {
      return true;
    }
    return false;
  };

  const schema = validationSchemaBankAccountRequestForm();
  const defaultValues = bankAccountRequestData ?? defaultValuesBankAccountRequestForm;

  const handleApproveInstrument = () => {
    return setPopupProps({
      title: 'Aprovar instrumento',
      message: 'Tem certeza que deseja aprovar esta solicitação?',
      children: (
        <ApproveInstrument
          onErrorCatch={handleErrorResponse}
          setPopupProps={setPopupProps}
          bankaccountId={bankAccountId!}
        />
      ),
    });
  };

  const handleRejectInstrument = () => {
    return setPopupProps({
      title: 'Rejeitar instrumento',
      message: 'Tem certeza que deseja rejeitar esta solicitação?',
      children: (
        <RejectInstrument
          onErrorCatch={handleErrorResponse}
          setPopupProps={setPopupProps}
          bankaccountId={bankAccountId!}
        />
      ),
    });
  };

  const handleDraftpreview = () => {
    return setPopupProps({
      title: "Gerar contrato",
      message: "",
      children: (
        <DraftPreviewContainer {...{
          onClose: () => setPopupProps(undefined),
          onError: handleErrorResponse,
          onSueccess: () => {
            showSuccessToast("Sucesso ao gerar contrato", "", setToast);
            setPopupProps(undefined);
          }
        }} />
      ),
    });
  };

  const handleCancelModal = () => {
    return setPopupProps({
      title: "Cancelar solicitação",
      message: "",
      children: (
        <CancelBankAccountRequestContainer
          bankAccountRequestData={bankAccountRequestData}
          onClose={() => setPopupProps(undefined)}
          setToast={setToast}
        />
      ),
    });
  };

  return (
    <GenericErrorBoundary
      status={bankAccountRequestStatus}
      error={bankAccountRequestErr}
      fallback="solicitação de conta"
    >
      <GenericFormSkeleton isLoading={isLoading && formMode === 'update'}>
        <React.Fragment>
          <ApiErrorAlert error={error} />
          <Toast toast={toast} setToast={setToast} />
          <RefreshProgress refreshing={submitting} />
          <FormProvider
            validationSchema={schema}
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            readOnly={isReadOnly()}
          >
            <BankAccountForm
              mode={formMode}
              refetch={refetch}
              bankAccountId={bankAccountId!}
              permissionsData={permissionsData}
              onUploadDocs={onUploadDocs}
              openBankAccountRequest={() => openBankAccountRequest()}
              onAssignBankAccountRequest={() => setHandleAction('assign')}
              onDelete={() => setHandleAction('delete')}
              deleteCheckList={() => setHandleAction('deleteCheckList')}
              onChangeStatus={() => setHandleAction('update')}
              onApprove={() => setHandleAction('approve')}
              onDisapprove={() => setHandleAction('disapprove')}
              submitApproval={submitApprovalBankAccountRequest}
              onSendApprovalRevision={onSendApprovalRevision}
              timeline={bankAccountRequestData?.timeline ?? []}
              statusDisplay={bankAccountRequestData?.statusDisplay ?? ''}
              onApproveInstrument={handleApproveInstrument}
              onRejectInstrument={handleRejectInstrument}
              onApproveSignature={() => setHandleAction('approveSignature')}
              onDisapproveSignature={() => setHandleAction('disapproveSignature')}
              onDraftpreview={handleDraftpreview}
              cancelBankAccountRequest={handleCancelModal}
            />
          </FormProvider>

          <Popup
            open={!!popupProps}
            title={popupProps?.title}
            text={popupProps?.message}
            onClose={() => setPopupProps(undefined)}
            children={popupProps?.children}
          />

          <AssignDrawer
            recordId={bankAccountId!}
            recordType="BankAccountRequest"
            openDrawer={handleAction === 'assign'}
            onClose={onClose}
            navigate="/contas-digitais/solicitacoes"
          />
          {handleAction === 'delete' && (
            <Modal
              open={!!handleAction}
              onClose={onClose}
              title="Excluir conta bancária"
              description="Tem certeza que deseja excluir esta conta bancária?"
              children={
                <GenericActionHandler
                  handleSubmit={deletedBankAccountRequest}
                  onClose={onClose}
                  isModal
                />
              }
            />
          )}
          {handleAction === 'deleteCheckList' && (
            <Modal
              open={!!handleAction}
              onClose={onClose}
              title="Remover restrições"
              description="Escolha quais restrições deseja remover desse registro"
              children={
                <DeleteCheckListContainer
                  onClose={onClose}
                  personId={bankAccountRequestData?.ownerId!}
                />
              }
            />
          )}
          {handleAction === 'update' && (
            <Modal
              open={!!handleAction}
              onClose={onClose}
              title="Definir status"
              description="Tem certeza que deseja alterar o status da conta?"
              children={
                <FormProvider
                  validationSchema={validationSchemaManualUpdateForm()}
                  defaultValues={defaultValuesManualUpdateForm}
                  onChangeField={[
                    {
                      fieldName: 'timelineAction',
                      delegate: (
                        value: string,
                        setValue: (name: any, value: any) => void
                      ) => setValue('timelineAction', value),
                    },
                  ]}
                  onSubmit={onSubmitManualUpdate}
                >
                  <ManualUpdateForm recordType="bankAccount" onClose={onClose} />
                </FormProvider>
              }
            />
          )}
          {!!handleAction && ['approve', 'disapprove'].includes(handleAction!) && (
            <Modal
              open={true}
              align="center"
              title={
                handleAction === 'approve'
                  ? 'Aprovar solicitação de conta'
                  : 'Reprovar solicitação de conta'
              }
              description={
                handleAction === 'approve'
                  ? 'Tem certeza que deseja aprovar a solicitação?'
                  : 'Tem certeza que deseja reprovar a solicitação?'
              }
              onClose={onClose}
              children={
                <ApproveAndDisapproveFormContainer
                  setToast={setToast}
                  onClose={onClose}
                  refetch={() => { }}
                  bankAccountRowSelected={[]}
                  approveOrDesapprove={handleAction as "approve" | "disapprove"}
                  bankAccountId={bankAccountId}
                  notIsNewBankAccount={true}
                  bankAccountRequestData={bankAccountRequestData}
                />
              }
            />
          )}

          {!!handleAction && ['approveSignature', 'disapproveSignature'].includes(handleAction!) && (
            <Modal
              open={true}
              align="center"
              title={handleAction === 'approveSignature' ? 'Aprovar assinatura' : 'Reprovar assinatura'}
              description={handleAction === 'approveSignature' ? 'Tem certeza que deseja aprovar assinatura?' : 'Tem certeza que deseja reprovar assinatura?'}
              onClose={onClose}
              children={
                <ApprovalsSignatureForm
                  onClose={onClose}
                  resource={handleAction}
                  setToast={setToast}
                />
              }
            />
          )}


        </React.Fragment>
      </GenericFormSkeleton>
    </GenericErrorBoundary>
  );
};
