import { Grid, Stack, useMediaQuery } from '@mui/material';
import { Button, CloseIcon, PersonIcon, SaveIcon } from '@uy3/web-components';
import { FunctionComponent } from 'react';
import { activeTheme } from 'services/theme';
import { useFormContext } from 'contexts/formContext';
import { SelectFormField, SelectOption } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { iconSx } from 'contexts/apiRequestContext';
import { handlePersonLink } from 'helpers/methods/GenericMethods';

type SignatoriesProps = {
    typeOfRelationship: SelectOption[];
    signatureType: SelectOption[];
    signatureValidation: SelectOption[];
    onClose: () => void;
    personList: AutocompleteOptionList;
    setIsAddPerson: Function;
};

const theme = activeTheme();

export const SignatoriesForm: FunctionComponent<SignatoriesProps> = ({
    typeOfRelationship,
    signatureType,
    signatureValidation,
    onClose,
    personList,
    setIsAddPerson,
}) => {
    const { submitting, watch } = useFormContext();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [personId, personDiscriminator] = watch(['personId', 'personDiscriminator']);

    let onlyNaturalPerson = personList.listOptions?.filter(
        (person: any) => person?.discriminator === 'NaturalPerson'
    );

    return (
        <Stack spacing={4}>
            <Grid>
                <AutocompleteField
                    name="personId"
                    label="Envolvido"
                    displayName="personIdDisplay"
                    required
                    listOptions={onlyNaturalPerson!}
                    loading={personList?.loading}
                    onSearch={personList?.onSearch}
                    endIconType="link"
                    link={handlePersonLink(personId, personDiscriminator)}
                    showEndIcon={personId}
                />
                <Grid container sx={{ justifyContent: 'flex-end', display: 'flex', mt: 1 }}>
                    <Button
                        name="btn-add-person"
                        startIcon={
                            <PersonIcon
                                htmlColor={theme.palette.primary.main}
                                width={22}
                                height={22}
                            />
                        }
                        variant="text"
                        onClick={() => setIsAddPerson(true)}
                    >
                        Adicionar envolvido
                    </Button>
                </Grid>
            </Grid>

            <SelectFormField
                name="typeOfRelationship"
                label="Tipo de relação"
                variant="outlined"
                options={typeOfRelationship}
                fullWidth
                required
            />

            <SelectFormField
                name="signatureType"
                label="Forma de Envio"
                variant="outlined"
                options={signatureType}
                fullWidth
            />

            <SelectFormField
                name="signatureValidation"
                label="Forma de Validação"
                variant="outlined"
                options={signatureValidation}
                fullWidth
            />

            <Stack
                direction={isMobile ? 'column' : 'row'}
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
            >
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                    fullWidth={isMobile}
                >
                    Fechar
                </Button>
                <Button
                    type="submit"
                    startIcon={<SaveIcon htmlColor={theme.palette.common.white} sx={iconSx} />}
                    variant="contained"
                    size="medium"
                    disabled={submitting}
                    fullWidth={isMobile}
                >
                    Adicionar signatário
                </Button>
            </Stack>
        </Stack>
    );
};
