import { FormMode, FormProvider } from 'contexts/formContext';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ApiResponseError,
    IBase,
    ToastType,
    showErrorToast,
    showSuccessToast,
    showWarningToast,
    toastState,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { RefreshProgress } from 'components/RefreshProgress';
import FundForm from './FundForm';
import { FundFormSchema, defaultValuesFundForm, validationSchemaFundForm } from './FundSchema';
import { useFundData, useFundMutation } from 'contexts/fundContext';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import React, { useState } from 'react';
import AssignDrawer from 'components/Assign/AssignDrawer';
import Toast from 'components/Toast/Toast';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import DeleteFund from '../DeleteFund/DeleteFund';
import { GenericFormSkeleton } from 'components/Skeleton/GenericFormSkeleton';
import GenericErrorBoundary from 'components/Errors/ErrorBoundary/GenericErrorBoundary';
import { useUserPermissionData } from 'contexts/userContext';
import { Modal } from '@uy3/web-components';
import { CloneRegisterContainer } from 'components/CloneRegister/CloneRegisterContainer';
import { createFundAsync } from 'services/fund';
import { useIdentity } from 'contexts/identityContext';
import { redirectCloneRegister } from 'helpers/methods/RedirectCloneRegister';
import { FieldValues } from 'react-hook-form';
import { hasCustomPermission } from 'helpers/methods/GenericMethods';
import { FundReadModel } from 'services/fund/types/fundReadModel';
import { useTenant } from 'contexts/tenantContext';

export const FundFormContainer = () => {
    const { fundId } = useParams();
    const { token } = useIdentity();
    const formMode: FormMode = fundId !== 'novo' ? 'update' : 'create';
    const { fundData, fundErr, fundStatus, refetch, isLoading } = useFundData(fundId!);
    const { submitError, submitting, setSubmitError } = useApiRequest();
    const { hasPermission } = useUserPermissionData();
    const { setFormFieldsErrors } = useFormFieldsError();
    const navigate = useNavigate();
    const [genericAction, setGenericAction] = useState<string | undefined>(undefined);
    const [toast, setToast] = useState<ToastType>(toastState);
    const onCloseGenricAction = () => setGenericAction(undefined);
    const { isRootTenancy } = useTenant();
    
    const isReadOnly = (): boolean => {
        const typePermission = formMode === 'create' ? 'Create' : 'Update'
        const resource = 'Fund';
        const permissionCustom = hasCustomPermission(resource, typePermission, hasPermission);
        
        if (!permissionCustom || !isRootTenancy) return true;
        return false;
    };

    const handleCloneFund = async (values: FieldValues) => {
        return await createFundAsync(fundData!, token!, values?.tenant)
            .then((response: any) => {
                return redirectCloneRegister({
                    redirect: () => navigate(`/cadastro/fundos/${response?.data!}`),
                    onClose: onCloseGenricAction,
                    setToast
                });
            }).catch((error: ApiResponseError) => onError(error))
    }

    const onSuccess = (data: FundReadModel) => {
        const id = formMode === 'create' ? data : data.id ?? fundId;
        navigate(`/cadastro/fundos/${id}`);
        if (fundId !== 'novo') refetch();
        setSubmitError(undefined);
        setFormFieldsErrors([]);
        const title = 'Fundo salvo com sucesso!';
        const description = 'Ótimo, agora você pode cadastrar ou editar um fundo.';
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        const { errorMessage, fieldErrors, warningToastError } = mapErrorResponse(error);
        setFormFieldsErrors(fieldErrors ?? []);
        setSubmitError(error);
        if (warningToastError && warningToastError?.length > 0) {
            const title = 'Atenção!';
            showWarningToast(title, warningToastError, setToast);
        }
        else {
            const title = 'Ops, ocorreu um erro!';
            const description = errorMessage;
            showErrorToast(title, description, setToast);
        }
    };

    const { mutateAsync } = useFundMutation(fundId!, onSuccess, onError);

    const onSubmit = async (values: FundFormSchema) => {
        await mutateAsync(values);
    };
    const schema = validationSchemaFundForm();
    const defaultValues = fundData ?? defaultValuesFundForm;

    return (
        <GenericFormSkeleton isLoading={isLoading && formMode === 'update'}>
            <GenericErrorBoundary status={fundStatus} error={fundErr} fallback="fundo">
                <Toast toast={toast} setToast={setToast} />
                <ApiErrorAlert error={submitError} />
                <RefreshProgress refreshing={submitting} />
                <FormProvider
                    validationSchema={schema}
                    defaultValues={defaultValues}
                    onSubmit={onSubmit}
                    readOnly={isReadOnly()}
                >
                    <FundForm
                        mode={formMode}
                        fundId={fundId!}
                        refetch={refetch}
                        setToast={setToast}
                        setGenericAction={setGenericAction}
                    />
                </FormProvider>
                <AssignDrawer
                    recordId={fundId!}
                    recordType="Fund"
                    openDrawer={genericAction === 'assign'}
                    onClose={onCloseGenricAction}
                    navigate={`/cadastro/fundos`}
                />
                <DeleteFund
                    isFundDeletedByForm
                    fundId={fundId!}
                    openPopup={genericAction === 'delete'}
                    onClosePopup={onCloseGenricAction}
                    refetch={refetch}
                    setToast={setToast}
                    fundName={fundData?.name!}
                />
                <Modal
                    open={genericAction === 'clone'}
                    align='left'
                    title={`Clonar ${fundData?.name}`}
                    description='Realize uma cópia do registro atual informando o correspondente.'
                    onClose={onCloseGenricAction}
                    children={(
                        <CloneRegisterContainer
                            data={fundData! as IBase}
                            onClose={onCloseGenricAction}
                            resource='fund'
                            onSubmit={handleCloneFund}
                        />
                    )}
                />
            </GenericErrorBoundary>
        </GenericFormSkeleton>
    );
};
