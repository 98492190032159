/* eslint-disable react-hooks/exhaustive-deps */
import { GridColDef } from '@mui/x-data-grid'
import { DataTable } from '@uy3/web-components'
import { maskCurrencyInCents } from 'helpers/formats/Currency'
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber'
import { isValidString } from 'helpers/formats/StringFormats'
import { localPaginateDataTable } from 'helpers/methods/GenericMethods'
import { IAdicional } from 'services/remessasVortx/remessasVortx.type'

type AdditionalsListProps = {
    queryData: IAdicional[]
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    page: number;
}

export const AdditionalsList = ({ queryData, page, rowsPerPage, setPage, setRowsPerPage }: AdditionalsListProps) => {


    const columns: GridColDef[] = [
        {
            field: 'cpf',
            headerName: 'CPF',
            hideSortIcons: true,
            editable: false,
            flex: 1,
            renderCell: ({ value }) => formatDocumentNumber(value)

        },
        {
            field: 'protocolo',
            headerName: 'Protocolo',
            hideSortIcons: true,
            editable: false,
            flex: 1
        },
        {
            field: 'dataPrevistaRepasse',
            headerName: 'Data prevista',
            hideSortIcons: true,
            editable: false,
            flex: 1,
            renderCell: ({ value }) => {
                let date = String(value);
                let year = date.slice(0, 4)
                let month = date.slice(4).slice(2)
                let day = date.slice(6)
                return new Date(`${year}-${month}-${day}T00:00:00`).toLocaleDateString('pt-br')
            }
        },
        {
            field: 'dataRepasse',
            headerName: 'Data repasse',
            hideSortIcons: true,
            editable: false,
            flex: 1,
            renderCell: ({ value }) => isValidString(String(value)) ? value : "N/D"
        },
        {
            field: 'valorGarantia',
            headerName: 'Valor garantia',
            hideSortIcons: true,
            editable: false,
            flex: 1,
            renderCell: ({ value }) => `${maskCurrencyInCents(value)}`
        }
    ];

    return (
        <DataTable
            headerBarWrapper={{
                header: 'Parcelas nao encontradas'
            }}
            columns={columns}
            rows={localPaginateDataTable(queryData, page, rowsPerPage)}
            page={page}
            rowCount={queryData?.length}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            getRowId={(x) => x?.cpf + x?.protocolo + x?.valorGarantia}
            enableJumpAction={false}
            filterMode='client'
        />
    )
}
