import { string, number, lazy, mixed, object, array, boolean } from "yup";

import { DefaultWarranty, HomeEquityWarranty, InvoiceFactoring, JudiciaryProcessWarranty, VehicleWarranty, WarrantyType, PublicPayroll, Consortium } from "./WarrantyTypes";
import { validateRequiredFields } from "helpers/validations/RequiredFieldsValidations";

const genericRequired = 'precisa ser preenchido.';

export const validationSchemaWarranty = lazy((value) => {
    let baseShape = {
        warrantyType: mixed<WarrantyType>()
            .oneOf(["Vehicle", "HomeEquity", "JudiciaryProcess", "Factoring", "PublicPayroll", "InvoiceFactoring", "Consortium"], "Selecione um tipo de garantia").required("Tipo de garantia: " + genericRequired),
    };

    if (value.warrantyType === 'Factoring') {
        return object()
            .shape({
                ...baseShape,
                totalValue: number()
                    .typeError('Valor total inválido.')
                    .required('Valor total: ' + genericRequired)
                    .min(0, 'Valor total não pode ser menor que 0.'),

                register: validateRequiredFields(
                    string().typeError('Registro inválido.'),
                    'register',
                    'Registradora'
                ),
                paymentNetworks: validateRequiredFields(
                    array().of(string().typeError('Arranjos de pagamentos inválida.')),
                    'paymentNetworks',
                    'Arranjos de pagamentos'
                ),

                type: string()
                    .typeError('Tipo inválido.')
                    .oneOf(['Collateral', 'OwnershipChange'], 'Selecione um tipo de garantia')
                    .required('Tipo de garantia: ' + genericRequired),
                splitMethodType: string()
                    .typeError('Tipo de divisão inválido.')
                    .oneOf(['Percentage', 'Absolute'], 'Selecione um tipo de divisão')
                    .required('Tipo de divisão: ' + genericRequired),
                constituted: boolean()
                    .typeError('Recebível Performado inválido.')
                    .required('Recebível Performado: ' + genericRequired),
            })
            .transform((value: any, _: any) => {
                return new VehicleWarranty(value);
            });
    }

    if (value.warrantyType === "Vehicle") {
        return object().shape({
            ...baseShape,
            renavam: string().typeError("Renavam inválido.").required("Renavam: " + genericRequired),
            board: string().typeError("Placa inválida.").required("Placa: " + genericRequired),
            typeOfVehicle: string().typeError("Tipo de veículo inválido.").required("Tipo: " + genericRequired),
            chassis: string().typeError("Chassi inválido.").required("Chassi" + genericRequired),
            supplier: string().typeError("Fornecedor inválido.").required("Fornecedor: " + genericRequired),
            taxNumberOfSupplier: string().typeError("CNPJ do fornecedor inválido.").required("CNPJ do fornecedor: " + genericRequired).cpfCnpjValidation("CNPJ inválido."),
            brand: string().typeError("Marca inválida.").required("Marca: " + genericRequired),
            color: string().typeError("Cor inválida.").required("Cor: " + genericRequired),
            model: string().typeError("Modelo inválido.").required("Modelo:" + genericRequired),
            yearOfManufacture: string().typeError("Ano de fabricação inválido.").required("Ano de fabricação: " + genericRequired).max(4, "Ano de fabricação precisa conter 4 digitos.").min(4, "Ano de fabricação não pode ser menor que 4 digitos."),
            yearOfModel: string().typeError("Ano do modelo inválido.").required("Ano do modelo: " + genericRequired).max(4, "Ano do modelo precisa conter 4 digitos.").min(4, "Ano do modelo não pode ser menor que 4 digitos."),
            typeOfFuel: string().typeError("Tipo de combustível inválido.").required("Tipo de combustível: " + genericRequired),
            downPayment: number().nullable().notRequired(),
            sellerCompanyName: string().nullable().notRequired(),
            sellerRegistrationNumber: string().nullable().notRequired(),
            valueInCash: number().nullable().notRequired(),
            subtotalAmount: number().nullable().notRequired(),
            totalAmountFinancedTaxFree: number().nullable().notRequired(),
        })
            .transform((value: any, _: any) => {
                return new VehicleWarranty(value);
            });
    }

    if (value.warrantyType === "HomeEquity") {
        return object().shape({
            ...baseShape,
            registryOffice: string().typeError("Cartório de registro " + genericRequired).required("Cartório de registro: " + genericRequired),
            registrationNumber: string().typeError("Matricula " + genericRequired).required("Matricula: " + genericRequired),
            width: number().typeError("Largura em metros " + genericRequired).required("Largura em metros: " + genericRequired),
            length: number().typeError("Comprimento em metros " + genericRequired).required("Comprimento em metros: " + genericRequired),
            addressName: string().typeError("Endereço " + genericRequired).required("Endereço: " + genericRequired),
            zipCodeAddress: string().typeError("CEP " + genericRequired).required("CEP: " + genericRequired),
            uf: string().typeError("UF " + genericRequired).required("UF: " + genericRequired),
            district: string().typeError("Bairro " + genericRequired).required("Bairro: " + genericRequired),
            addressNumber: string().typeError("Número " + genericRequired).required("Número: " + genericRequired),
            complementAddress: string().typeError("Complemento " + genericRequired).required("Complemento: " + genericRequired),
            propertyFeatures: string().nullable(),
        })
            .transform((value: any, _: any) => {
                return new HomeEquityWarranty(value);
            });
    }

    if (value.warrantyType === "JudiciaryProcess") {
        return object().shape({
            ...baseShape,
            number: string().typeError("Nº do processo" + genericRequired).required("Nº do processo: " + genericRequired),
            court: string().typeError("Vara / Tribunal" + genericRequired).required("Vara / Tribunal: " + genericRequired),
            valueOfCause: number().typeError("Valor da causa" + genericRequired).required("Valor da causa: " + genericRequired),
            claimant: string().typeError("Requerente / Autor" + genericRequired).required("Requerente / Autor: " + genericRequired),
            claimed: string().typeError("Requerido / Réu" + genericRequired).required("Requerido / Réu: " + genericRequired),
            typeOfProcess: string().typeError("Tipo do processo" + genericRequired).required("Tipo do processo: " + genericRequired),
        })
            .transform((value: any, _: any) => {
                return new JudiciaryProcessWarranty(value);
            });
    }

    if (value.warrantyType === "PublicPayroll") {
        return object().shape({
            ...baseShape,
            payrollAgreementId: string().typeError("Convênio" + genericRequired).required("Convênio: " + genericRequired),
            employeeCode: string().typeError("Código da matricula" + genericRequired).required("Código da matricula: " + genericRequired),
            agreementCode: string().typeError("Código externo do convênio" + genericRequired).required("Código externo do convênio: " + genericRequired),
            paymentTransferDay: string().typeError("Dia do repasse" + genericRequired).required("Dia do repasse: " + genericRequired),
            paymentAmountInCents: string().typeError("Valor da margem" + genericRequired).required("Valor da margem: " + genericRequired),
            employmentStatusCode: string().typeError("Código situação vínculo funcional" + genericRequired).required("Código situação vínculo funcional: " + genericRequired),
            employmentStatusDescription: string().typeError("Descrição situação vínculo funcional" + genericRequired).required("Descrição situação vínculo funcional: " + genericRequired),
        })
            .transform((value: any, _: any) => {
                return new PublicPayroll(value);
            });
    }

    if (value.warrantyType === "InvoiceFactoring") {
        return object().shape({
            ...baseShape,
            payerName: string().typeError(`Nome do pagador: ${genericRequired}`).required(`Nome do pagador: ${genericRequired}`),
            payerRegistrationNumber: string().typeError(`CPF/CNPJ ${genericRequired}`).required(`CPF/CNPJ ${genericRequired}`).cpfCnpjValidation('CPF/CNPJ: inválido'),
            contractNumber: string().typeError(`Número do contrato ${genericRequired}`).required(`Número do contrato ${genericRequired}`),
            invoiceValue: string().typeError(`Valor nominal ${genericRequired}`).required(`Valor nominal ${genericRequired}`),
            invoiceNumber: string().typeError(`Número nominal ${genericRequired}`).required(`Número nominal ${genericRequired}`),
            discountValue: string().typeError(`Valor desconto: ${genericRequired}`).required(`Valor desconto: ${genericRequired}`),
            presentValue: string().typeError(`Valor presente ${genericRequired}`).required(`Valor presente ${genericRequired}`),
            dueDate: string().typeError(`Data de vencimento ${genericRequired}`).required(`Data de vencimento ${genericRequired}`),
            paymentAmountInCents: string().typeError(`Valor do pagamento ${genericRequired}`).required(`Valor do pagamento ${genericRequired}`),
        })
            .transform((value: any, _: any) => {
                return new InvoiceFactoring(value);
            });
    }

    if (value.warrantyType === "Consortium") {
        return object().shape({
            ...baseShape,
            administratorName: string().nullable(),
            administratorRegistrationNumber: string().cpfCnpjValidation('CNPJ: inválido').nullable(),
            group: string().typeError(`Grupo ${genericRequired}`).required(`Grupo ${genericRequired}`),
            quota: string().typeError(`Cota ${genericRequired}`).required(`Cota ${genericRequired}`),
        })
            .transform((value: any, _: any) => {
                return new Consortium(value);
            });
    }

    return object().shape({ ...baseShape });
});

export const defaultValuesWarranty = (warrantyType: WarrantyType | undefined) => {

    if (warrantyType === 'InvoiceFactoring') {
        return {
            payerName: null,
            payerRegistrationNumber: null,
            contractNumber: null,
            invoiceValue: null,
            invoiceNumber: null,
            discountValue: null,
            presentValue: null,
            dueDate: new Date(),
            emissionDate: new Date(),
            paymentAmountInCents: null
        }
    }
    return new DefaultWarranty()
};