/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { CloudUpload } from '@mui/icons-material';
import { Box, Grid, InputAdornment, Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import {
    CheckboxFormField,
    DatePickerFormField,
    SelectFormField,
    SelectOption,
    TextFormField,
} from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import PercentageFormField from 'components/Forms/FormFields/PercentageFormField/PercentageFormField';
import { TimeTextField } from 'components/Forms/FormFields/TimeTextField/TimeTextField';
import { useFormContext } from 'contexts/formContext';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { useEffect } from 'react';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { codeByCategoryName } from 'helpers/methods/OptionsMappeds';
import { useParams } from 'react-router';
import { TooltipComponent } from 'components/Tooltip/Tooltop';
import { categoryNameOptions, OptionType } from 'services/creditProduct/types/creditProductReadModel';

type InformationCreditProductProps = {
    openDrawerDocument: () => void;
    fundList: AutocompleteOptionList;
    tenantAutoCompleteProps: AutocompleteOptionList;
    modalityList: AutocompleteOptionList;
    subModalityList: AutocompleteOptionList;
    setGetCodeModalite: React.Dispatch<React.SetStateAction<number | undefined>>;
};

type itemsProps = {
    label: string;
    code: number;
};

export const ammTypeOptions = [
    { label: 'Price', value: 'CleanPrice' },
    { label: 'Price Coeficiente', value: 'PriceCoefficient' },
    { label: 'Price com garantia fiduciária', value: 'ReceivablesPrice' },
    { label: 'FGTS', value: 'FGTS' },
    { label: 'Financiamento estudantil', value: 'StudentLoan' },
    { label: 'SAC (B3)', value: 'SAC' },
    { label: 'PRICE (B3)', value: 'Price' },
    { label: 'Desconto', value: 'Discount' },
];

function InformationCreditProduct({
    openDrawerDocument,
    fundList,
    tenantAutoCompleteProps,
    modalityList,
    subModalityList,
    setGetCodeModalite,
}: InformationCreditProductProps) {
    const { watch, setValue, readOnly } = useFormContext();
    const { productId } = useParams();
    const isNew = productId === 'novo';
    const signatureValidationStep = watch('signatureValidationStep');
    const signaturePortalProvider = watch('signaturePortalProvider');
    const categoryName = watch('categoryName');
    const creditProductModalityIdDisplay = watch('creditProductModalityIdDisplay');

    const handleSignatureValidationStep = () => {
        if (signatureValidationStep === false) {
            setValue('maximumValueWithoutSignatureValidation', null);
        }
    };

    const handleSignaturePortalProvider = () => {
        if (signaturePortalProvider === 2) {
            setValue('maximumValueWithoutDigitalSignature', 0);
            setValue('signatureType', 0);
            setValue('signatureValidation', 'EletronicSignature');
        }
    };

    const handleCategoryName = () => {
        const option = codeByCategoryName<OptionType>({
            name: categoryName,
            options: categoryNameOptions,
        });
        if (option !== undefined) setValue('code', option.code);
    };

    useEffect(() => {
        if (creditProductModalityIdDisplay) {
            modalityList.listOptions.map(
                ({ label, code }: Partial<itemsProps>) =>
                    label === creditProductModalityIdDisplay && setGetCodeModalite(code)
            );
        }

        handleSignatureValidationStep();
        handleSignaturePortalProvider();
        handleCategoryName();
        () => setGetCodeModalite(undefined);

    }, [watch, setValue, creditProductModalityIdDisplay]);

    const instrumentTypeOptions = [
        { label: 'Nota Comercial', value: 'CommercialPapper' },
        { label: 'CCB', value: 'CCB' },
        { label: 'Consignado Público', value: 'PublicPayrollLoan' },
        { label: 'Cessão de créditos', value: 'CreditAssignment' }
    ];

    const optionsSignatureValidation = () => {
        const options: { [type: number]: SelectOption[] } = {
            0: [
                { label: 'Assinatura Eletrônica', value: 'EletronicSignature' },
                { label: 'Assinatura com Selfie', value: 'SelfieSignature' },
                { label: 'Certificado Digital', value: 'DigitalCertificate' },
            ],
            1: [
                { label: 'Assinatura Eletrônica', value: 'EletronicSignature' },
                { label: 'Assinatura Eletrônica + Documento', value: 'EletronicSignatureAndDocument' },
                { label: 'Assinatura com Selfie', value: 'SelfieSignature' },
                { label: 'Certificado Digital', value: 'DigitalCertificate' },
                { label: 'Selfie + Documento Oficial', value: 'SelfieDocumentSignature' },
                { label: 'Biometria Facial', value: 'FacialBiometrics' },
            ],
        };
        return options[signaturePortalProvider];
    };

    return (
        <Box py={3}>
            <Typography
                variant="h5"
                color="neutral.dark"
                sx={{
                    lineHeight: { xs: '24px', md: '24px' },
                    fontWeight: { xs: '700', md: '700' },
                    fontStyle: { xs: 'normal', md: 'normal' },
                }}
            >
                Dados do produto de crédito
            </Typography>
            <Stack direction="column" mt={2}>
                <Grid container spacing={3} columns={10}>
                    <Grid item xs={3}>
                        <TextFormField
                            name="name"
                            label="Nome"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectFormField
                            name="categoryName"
                            label="Nome simplificado"
                            variant="outlined"
                            fullWidth
                            required
                            options={categoryNameOptions}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectFormField
                            fullWidth
                            required
                            label="Tipo de cálculo"
                            name="amortizationType"
                            options={ammTypeOptions}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectFormField
                            fullWidth
                            required
                            label="Tipo de instrumento"
                            name="instrumentType"
                            options={instrumentTypeOptions}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <PercentageFormField
                            name="minimumInterestRate"
                            label="Taxa de Juros Mínima (%)"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <PercentageFormField
                            name="maximumInterestRate"
                            label="Taxa de Juros Máxima (%)"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <AutocompleteField
                            name="creditProductModalityId"
                            label="Modalidades"
                            displayName="creditProductModalityIdDisplay"
                            required
                            {...modalityList!}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <AutocompleteField
                            name="creditProductSubModalityId"
                            label="Submodalidades"
                            displayName="creditProductSubModalityIdDisplay"
                            required
                            disabled={!creditProductModalityIdDisplay || readOnly}
                            {...subModalityList!}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextFormField
                            name="cosifBankAccount"
                            label="Conta COSIF"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <SelectFormField
                            fullWidth
                            required
                            label="Visibilidade do Produto"
                            name="viewType"
                            options={[
                                { label: 'Aberto', value: 'TopToBottom' },
                                { label: 'Fechado', value: 'BottomToTop' },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CurrencyFormField
                            name="minimumPrincipalAmount"
                            label="Valor mínimo do principal"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1.5}>
                        <CurrencyFormField
                            name="maximumPrincipalAmount"
                            label="Valor máximo do principal"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={1.5}>
                        <CurrencyFormField
                            label="Valor máximo para liquidação automática"
                            name="maximumAutomaticLiquidationValueInCents"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Stack>

            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '50px', md: '50px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Prazo mínimos e máximos de limites e juros
                </Typography>

                <Grid container spacing={3} columns={10}>
                    <Grid item xs={1.5}>
                        <TextFormField
                            label="Prazo mínimo de amortização"
                            name="minimumAmortizationPeriods"
                            fullWidth
                            variant="outlined"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={1.5}>
                        <TextFormField
                            label="Prazo máximo da amortização"
                            name="maximumAmortizationPeriods"
                            fullWidth
                            variant="outlined"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFormField
                            label="Prazo máximo de carência de juros"
                            name="maximumAbsInterestPeriods"
                            fullWidth
                            variant="outlined"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextFormField
                            label="Prazo máximo de carência de amortização"
                            name="maximumAbsAmortizationPeriods"
                            fullWidth
                            variant="outlined"
                            type="number"
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Custo de emissão
                </Typography>
                <Stack direction="column" mt={2}>
                    <Grid container spacing={3} columns={10}>
                        {/* <Grid item xs={3}>
                            <SelectFormField
                                fullWidth
                                required
                                label="Tipo de Custo de Emissão"
                                name="bankComission.type"
                                options={[
                                    { label: 'Valor Fixo', value: 'Absolute' },
                                    { label: 'Percentual', value: 'Percentage' },
                                ]}
                            />
                        </Grid>

                        {watch('bankComission.type') === 'Absolute' && (
                            <Grid item xs={3}>
                                <CurrencyFormField
                                    required
                                    variant="outlined"
                                    fullWidth
                                    id="bankComission.amount"
                                    label="Custo de Emissão"
                                    name="bankComission.amount"
                                />
                            </Grid>
                        )}
                        {watch('bankComission.type') === 'Percentage' && (
                            <Grid item xs={3}>
                                <PercentageFormField
                                    required
                                    name="bankComission.amount"
                                    label="Custo de Emissão (%)"
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                        )} */}
                        <Grid item xs={3}>
                            <TextFormField
                                name="numberDaysValidityCustomerRegistration"
                                label="Validade do cadastro (dias)"
                                variant="outlined"
                                type="number"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CurrencyFormField
                                label="Custo mínimo de emissão"
                                name="minimumEmissionCostInCents"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CurrencyFormField
                                variant="outlined"
                                fullWidth
                                label="Custo máximo de emissão"
                                name="maximumEmissionCostInCents"
                            />
                        </Grid>
                    </Grid>
                    <Stack direction='row' alignItems='center'>
                        <Grid item>
                            <CheckboxFormField
                                color="primary"
                                name="financeEmissioncost"
                                label="Financiar custo de emissão"
                            />
                        </Grid>
                        <Grid item>
                            <CheckboxFormField
                                color="primary"
                                name="financeFinTax"
                                label="Financiar IOF"
                            />
                        </Grid>

                    </Stack>
                </Stack>
            </Box>

            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Horário de Corte
                </Typography>

                <Stack direction="column" mt={2}>
                    <Grid container spacing={3} columns={10}>
                        <Grid item xs={3}>
                            <TimeTextField
                                label="Envio para aprovação"
                                variant="outlined"
                                fullWidth
                                name="timeLimitToSubmitApproval"
                                type="time"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TimeTextField
                                label="Aprovação de Compliance"
                                variant="outlined"
                                fullWidth
                                name="timeLimitToApprove"
                                type="time"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TimeTextField
                                label="Início das Assinaturas"
                                variant="outlined"
                                fullWidth
                                name="timeToSendToSignatures"
                                type="time"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TimeTextField
                                label="Assinatura"
                                variant="outlined"
                                fullWidth
                                name="timeLimitToSign"
                                type="time"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Box>

            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Aprovação
                </Typography>

                <Stack direction="column" spacing={4}>
                    <Grid container columns={10}>
                        <Grid item xs={2}>
                            <CheckboxFormField
                                name="requireWitness"
                                color="primary"
                                label="Obrigatório informar testemunhas"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckboxFormField
                                color="primary"
                                name="requireGuarantor"
                                label="Obrigatório informar avalista"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckboxFormField
                                color="primary"
                                name="instrumentApproval"
                                label="Etapa aprovação de instrumento"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckboxFormField
                                color="primary"
                                name="allowFinanceTaxExemption"
                                label="Permitir isenção de IOF"
                            />
                        </Grid>
                        <TooltipComponent
                            title='A abertura de conta transitória é usada para operações em que o beneficiário é terceiro e a operação requer a liquidação em conta de titularidade do devedor.'
                            placement='bottom'
                        >
                            <Grid>
                                <CheckboxFormField
                                    color="primary"
                                    name="openBankAccount"
                                    label="Abrir conta transitória do devedor"
                                />
                            </Grid>
                        </TooltipComponent>
                        <Grid item xs={2}>
                            <CheckboxFormField
                                color="primary"
                                name="isConsumerCreditNote"
                                label="Crédito Direto / Interveniente Quitante"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckboxFormField
                                color="primary"
                                name="limitOpToPerson"
                                label="Limite de operação por pessoa"
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Box>

            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Geração de Contrato
                </Typography>
                <Stack direction="column" spacing={4}>
                    <Grid container columns={10}>
                        <Grid item xs={3}>
                            <CheckboxFormField
                                color="primary"
                                name="draftGeneration"
                                label="Gerar Contrato CCB"
                            />
                        </Grid>
                        {watch('draftGeneration') === true && (
                            <Grid item xs={4}>
                                <TextFormField
                                    fullWidth
                                    label="Caminho do Arquivo Template"
                                    name="templateDoc.fileName"
                                    variant="outlined"
                                    disabled
                                    required={watch('useSignaturePortal') === true}
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                {!isNew && (
                                                    <InputAdornment position="end">
                                                        <TooltipComponent
                                                            title="Download do Arquivo"
                                                            placement="top"
                                                            arrow
                                                        >
                                                            <a
                                                                href={watch('templateDoc.tempGetUrl')}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <CloudDoneIcon />
                                                            </a>
                                                        </TooltipComponent>
                                                    </InputAdornment>
                                                )}
                                                <InputAdornment position="end">
                                                    <TooltipComponent
                                                        title="Atualizar Arquivo"
                                                        placement="top"
                                                        arrow
                                                    >
                                                        <span
                                                            onClick={openDrawerDocument}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <CloudUpload />
                                                        </span>
                                                    </TooltipComponent>
                                                </InputAdornment>
                                            </>
                                        ),
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Stack>
            </Box>

            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Motor de Compliance
                </Typography>

                <Stack direction="row" alignItems="center" spacing={4}>
                    <Grid container columns={10}>
                        <Grid item xs={3}>
                            <CheckboxFormField
                                color="primary"
                                name="automaticComplianceAnalysisExecution"
                                label="Execução automática de motor de compliance"
                                defaultChecked={true}
                            />
                        </Grid>
                        {watch('automaticComplianceAnalysisExecution') === true && (
                            <Grid item xs={4}>
                                <CheckboxFormField
                                    color="primary"
                                    name="automaticComplianceApproval"
                                    label="Aprovação automática de compliance"
                                />
                            </Grid>
                        )}
                        <Grid item xs={watch('automaticComplianceAnalysisExecution') === true ? 3 : 4}>
                            <TextFormField
                                name="defaultComplianceAnalysisGroup"
                                label="Grupo de Análise Compliance"
                                type="number"
                                variant="outlined"
                                fullWidth
                                required={
                                    watch('automaticComplianceAnalysisExecution') === true && true
                                }
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Box>

            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Análise de Crédito
                </Typography>
                <Stack direction="column" spacing={4}>
                    <Grid container columns={10}>
                        <Grid item xs={3}>
                            <CheckboxFormField
                                color="primary"
                                name="automaticCreditAnalysisExecution"
                                label="Execução automática de motor de crédito"
                            />
                        </Grid>
                        {watch('automaticCreditAnalysisExecution') === true && (
                            <Grid item xs={4}>
                                <CheckboxFormField
                                    color="primary"
                                    name="automaticCreditApproval"
                                    label="Aprovação automática de limite de crédito"
                                />
                            </Grid>
                        )}
                        <Grid item xs={watch('automaticCreditAnalysisExecution') === true ? 3 : 4}>
                            <TextFormField
                                name="defaultCreditAnalysisGroup"
                                label="Grupo de Análise de Crédito"
                                type="number"
                                fullWidth
                                variant="outlined"
                                required={
                                    watch('automaticCreditAnalysisExecution') === true && true
                                }
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Box>

            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Coleta de Assinaturas
                </Typography>
                <Stack direction="column" spacing={4}>
                    <Grid container columns={10}>
                        <Grid item xs={3}>
                            <CheckboxFormField
                                color="primary"
                                name="useSignaturePortal"
                                label="Coletar assinatura"
                            />
                        </Grid>
                        {watch('useSignaturePortal') === true && (
                            <Grid item xs={3} >
                                <CheckboxFormField
                                    color="primary"
                                    name="notifySigner"
                                    label="Notificar cliente"
                                />
                            </Grid>
                        )}
                    </Grid>
                </Stack>

                {watch('useSignaturePortal') === true && (
                    <Grid container columns={10} mt={1} spacing={3}>
                        {signaturePortalProvider !== 2 && (
                            <>
                                <Grid item xs={3}>
                                    <SelectFormField
                                        name="signatureType"
                                        label="Forma de envio de Assinatura"
                                        required={watch('useSignaturePortal') === true && true}
                                        fullWidth
                                        options={[
                                            { label: 'Email', value: 0 },
                                            { label: 'Sms', value: 1 },
                                            { label: 'Whatsapp', value: 2 },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <CurrencyFormField
                                        label="Valor máximo sem assinatura digital"
                                        name="maximumValueWithoutDigitalSignature"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={2}>
                            <SelectFormField
                                name="signaturePortalProvider"
                                label="Certificadora"
                                fullWidth
                                defaultValue=""
                                required={watch('useSignaturePortal') === true && true}
                                options={[
                                    { label: 'RBM', value: 0 },
                                    { label: 'ClickSign', value: 1 },
                                    { label: 'QuickSoft', value: 2 },
                                    { label: 'UnicoCheck', value: 3 },
                                ]}
                            />
                        </Grid>

                        {(signaturePortalProvider === 0 || signaturePortalProvider === 1) && (
                            <Grid item xs={3}>
                                <SelectFormField
                                    name="signatureValidation"
                                    label="Tipo de Assinatura"
                                    fullWidth
                                    required={signaturePortalProvider !== 2 ? true : false}
                                    options={optionsSignatureValidation()}
                                />
                            </Grid>
                        )}
                        {signaturePortalProvider === 3 && (
                            <>
                                <Grid item xs={2}>
                                    <TextFormField
                                        label="Nome template"
                                        name="unicoTemplateName"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextFormField
                                        type="number"
                                        variant="outlined"
                                        label="Score mínimo"
                                        fullWidth
                                        name="unicoMinimumScoreForSignatureValidation"
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                )}
            </Box>

            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Validação de Assinatura
                </Typography>

                <Stack direction="row" alignItems="center" spacing={4}>
                    <Grid container columns={10}>
                        <Grid item xs={3}>
                            <CheckboxFormField
                                color="primary"
                                name="signatureValidationStep"
                                label="Validação de Assinatura"
                            />
                        </Grid>
                        {watch('signatureValidationStep') === true && (
                            <Grid item xs={4}>
                                <CurrencyFormField
                                    label="Valor mínimo para validação de assinatura"
                                    name="maximumValueWithoutSignatureValidation"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        )}
                    </Grid>
                </Stack>
            </Box>

            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Fundo
                </Typography>

                <Stack flexDirection="column" spacing={4}>
                    <Grid container columns={10}>
                        <Grid item xs={3}>
                            <CheckboxFormField
                                color="primary"
                                name="allowOwnFund"
                                label="Permite Fundo Próprio UY3"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AutocompleteField
                                name="defaultFundId"
                                label="Fundo padrão"
                                displayName="defaultFundDisplay"
                                required={watch('allowOwnFund') === false && true}
                                {...fundList!}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Box>

            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Aprovações
                </Typography>

                <Stack direction="column">
                    <Grid container columns={10} pt={2} spacing={2}>
                        <Grid item xs={3}>
                            <AutocompleteField
                                label="Correspondente para aprovação do compliance"
                                name="complianceApprovalTenantName"
                                displayName="complianceApprovalTenantNameDisplay"
                                {...tenantAutoCompleteProps}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AutocompleteField
                                label="Correspondente para aprovação da liquidação"
                                name="liquidationApprovalTenantName"
                                displayName="liquidationApprovalTenantNameDisplay"
                                {...tenantAutoCompleteProps}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AutocompleteField
                                label="Correspondente para aprovação da validação de assinatura"
                                name="signatureApprovalTenantName"
                                displayName="signatureApprovalTenantNameDisplay"
                                {...tenantAutoCompleteProps}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Box>
            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Orgão de averbação
                </Typography>

                <Stack direction="row" alignItems="center" spacing={4}>
                    <Grid container columns={10} pt={2}>
                        <Grid item xs={3} mr={2}>
                            <SelectFormField
                                name='warrantyRegistrationOffice'
                                label='Orgão de averbação'
                                options={[
                                    { label: "Nenhum", value: "None" },
                                    { label: "FGTS", value: "Fgts" },
                                    { label: "Exército", value: "Army" },
                                    { label: "SIAPE", value: "Siape" },
                                    { label: "Outros", value: "Others" },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Box>
            <Box mt={3}>

                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Custos por parcela
                </Typography>
                <Stack direction="row" alignItems="center" >
                    <Grid container columns={10} pt={2} spacing={2}>
                        <Grid item xs={1.5}>
                            <CurrencyFormField
                                label="Custo fixo por parcela"
                                name="paymentFixedCosts"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={1.5}>
                            <CurrencyFormField
                                label="Valor da cobertura do seguro"
                                name="insuranceCoverAmount"
                                variant="outlined"
                                fullWidth
                            />

                        </Grid>
                    </Grid>
                </Stack>
            </Box>
            <Box mt={3}>

                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Vigência
                </Typography>
                <Stack direction="row" alignItems="center" >
                    <Grid container columns={10} pt={2} spacing={2}>
                        <Grid item xs={3}>
                            <DatePickerFormField
                                label='Data de início da vigência'
                                name='termStartDate'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DatePickerFormField
                                label='Data limite de lançamento da operação'
                                name='creditNotePostingDeadline'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DatePickerFormField
                                label='Data final da vigência'
                                name='termEndDate'
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Box>

            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Garantias
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <CheckboxFormField
                            color="primary"
                            name="useCollateral"
                            label="Utiliza Garantia"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CheckboxFormField
                            color="primary"
                            name="manualWarranty"
                            label="Garantia manual"
                        />
                    </Grid>
                    {watch('useCollateral') === true && (
                        <Grid item xs={12}>
                            <TextFormField
                                fullWidth
                                name="defaultCollateralDescription"
                                variant="outlined"
                                label="Descrição padrão da garantia"
                                multiline
                                rows={6}
                            />
                        </Grid>
                    )}
                </Grid>
                <Stack direction="column" mt={2}>
                    <Typography
                        variant="h5"
                        color="neutral.dark"
                        sx={{
                            lineHeight: { xs: '24px', md: '24px' },
                            fontWeight: { xs: '700', md: '700' },
                            fontStyle: { xs: 'normal', md: 'normal' },
                            mb: 2,
                        }}
                    >
                        Registro na CVM
                    </Typography>
                    <Grid container spacing={3} columns={10}>
                        <Grid item xs={4}>
                            <DatePickerFormField
                                label="Data de registro na CVM"
                                name="cvmRegistrationDate"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextFormField
                                type="number"
                                name="cvmRegistrationNumber"
                                label="Número de Registro na CVM"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextFormField
                                type="number"
                                name="cvmRegistrationNumberIndex"
                                label="Sequencial de Registro na CVM"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Box>
        </Box>
    );
}

export default InformationCreditProduct;
