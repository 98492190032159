import { Stack } from '@mui/material';
import { Button, CardData, CloseIcon, Typography } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { isEmpty } from 'lodash';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import React from 'react'
import { IVinculoFuncional } from 'services/datasets/datasets.interface'
import { activeTheme } from 'services/theme';
import { formatCurrencyInCents } from 'helpers/formats/Currency';

type SiapeMarginDetailsProps = {
    data: IVinculoFuncional;
    onClose: () => void;
}

const theme = activeTheme();

export const SiapeMarginDetails: React.FC<SiapeMarginDetailsProps> = ({ data, onClose }) => {
    const productLength = data?.Produtos?.length ?? 0
    const hasProduct = productLength > 0;

    return (
        <Stack spacing={2}>
            <Typography variant='h5' fontSize={20} fontWeight='bold'>
                Informações
            </Typography>

            <CardData
                listItem={[
                    { id: '1', title: 'Código órgão', value: data?.CodOrgao ?? "N/D" },
                    { id: '2', title: 'CNPJ do órgão', value: formatDocumentNumber(data?.CnpjOrgao) ?? "N/D" },
                    { id: '3', title: 'Matrícula', value: data?.CdMatricula ?? "N/D" },
                    { id: '4', title: 'Código situação funcional', value: data?.CodSituacaoFuncional ?? "N/D" },
                    { id: '5', title: 'Descrição situação funcional', value: data?.DescSituacaoFuncional ?? "N/D" },
                    { id: '6', title: 'Código de classificação', value: data?.CodClassificacao ?? "N/D" },
                    { id: '7', title: 'Descrição de classificação', value: data?.DescClassificacao ?? "N/D" },
                    { id: '8', title: 'UF', value: data?.CdUfUpag ?? "N/D" },
                    { id: '9', title: 'Banco', value: data?.BancoDomicBancario ?? "N/D" },
                    { id: '10', title: 'Agência', value: data?.AgenciaDomicBancario ?? "N/D" },
                    { id: '11', title: 'Conta', value: data?.ContaDomicBancario ?? "N/D" },
                ]}
            />

            {hasProduct && (
                <Stack spacing={2}>
                    <Typography variant='h5' fontSize={20} fontWeight='bold'>{productLength > 1 ? `Produtos (${productLength})` : "Produto"}</Typography>
                    {data?.Produtos.map((x, i) => {
                        const id = `${x.CdConvenio + x.CdRubrica + x.DsRubrica + x.VlMargemDisp}`;
                        const autorizacaoEmprestimo = x.AutorizacaoEmprestimo
                        return (
                            <CardData
                                listItem={[
                                    { id, title: 'Código do Orgão', value: x.CdConvenio ?? "N/D" },
                                    { id, title: 'Código rubrica', value: x.CdRubrica ?? "N/D" },
                                    { id, title: 'Descrição rubrica', value: x.DsRubrica ?? "N/D" },
                                    { id, title: 'Valor margem disponível', value: formatCurrencyInCents(x.VlMargemDisp) }, 
                                    { id, title: 'Autorização emprestimo', value: `Autorização: ${autorizacaoEmprestimo.Autorizado} | Data: ${!isEmpty(autorizacaoEmprestimo.DtValidade) ? autorizacaoEmprestimo.DtValidade : ''}` ?? "N/D" },
                                    { id, title: 'Autorização cartão ', value: x.AutorizacaoCartao ?? "N/D" },
                                    { id, title: 'Autorização cartão benefício', value: x.AutorizacaoCartaoBeneficio ?? "N/D" },
                                    { id, title: 'Autorização sindical', value: x.AutorizacaoDescSindical ?? "N/D" },
                                    { id, title: 'Autorização mensal', value: x.AutorizacaoDescMensal ?? "N/D" },
                                ]}
                            />
                        )
                    })}
                </Stack>
            )}

            <Stack direction='row' alignItems='center' justifyContent='right'>
                <Button
                    startIcon={<CloseIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}
                    onClick={onClose}
                    variant='outlined'
                >
                    Fechar
                </Button>
            </Stack>

        </Stack>
    )
}
