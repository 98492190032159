export type WarrantyType = "Vehicle" | "HomeEquity" | "JudiciaryProcess" | "Factoring" | "PublicPayroll" | "InvoiceFactoring" | "Consortium";
export type Warranty = VehicleWarranty | HomeEquityWarranty | JudiciaryProcessWarranty;

export class VehicleWarranty
    implements WarrantyItem {

    constructor(obj?: VehicleWarranty) {
        this.warrantyType = "Vehicle";

        if (obj) {
            Object.assign(this, obj);
        }
    };
    title() {
        return "Veículo";
    }
    description() {
        return ` Modelo ${this.brand} ${this.color}, ano ${this.yearOfManufacture} (modelo ${this.yearOfModel}), placa ${this.board} RENAVAM ${this.renavam}`;
    };

    readonly warrantyType: WarrantyType;
    renavam?: string;
    typeOfVehicle?: string;
    chassis?: string;
    board?: string;
    taxNumberOfSupplier?: string;
    brand?: string;
    color?: string;
    model?: string;
    yearOfManufacture?: number;
    yearOfModel?: number;
    typeOfFuel?: string;
    downPayment?: string;
    sellerCompanyName?: string;
    sellerRegistrationNumber?: string;
    valueInCash?: string;
    subtotalAmount?: string;
    totalAmountFinancedTaxFree?: string;

}

export class HomeEquityWarranty
    implements WarrantyItem {

    constructor(obj?: HomeEquityWarranty) {
        this.warrantyType = "HomeEquity";

        if (obj) {
            Object.assign(this, obj);
        }
    };

    description() {
        return `Rua ${this.addressName}, ${this.addressNumber} ${this.complementAddress} - ${this.district} - CEP ${this.zipCodeAddress} / ${this.uf}. Registrado em ${this.registryOffice} sob matrícula ${this.registrationNumber}. Dimensões: ${this.width}x${this.length} ${this.propertyFeatures}`;
    };

    title() {
        return `Imóvel`;
    };

    warrantyType: "HomeEquity";
    registryOffice?: string;
    registrationNumber?: string;
    width?: number;
    length?: number;
    addressName?: string;
    zipCodeAddress?: string;
    uf?: string;
    district?: string;
    addressNumber?: string;
    complementAddress?: string;
    propertyFeatures?: string;
}

export class JudiciaryProcessWarranty
    implements WarrantyItem {

    constructor(obj?: JudiciaryProcessWarranty) {
        this.warrantyType = "JudiciaryProcess";
        if (obj) {
            Object.assign(this, obj);
        }
    };

    description() {
        //@ts-ignore
        return `${this.typeOfProcess} nº ${this.number} - ${this.court} - ${this.claimant} vs ${this.claimed} - Valor da causa: ${Helper.formatCurrency((this.valueOfCause || 0) * 100)}`;
    };

    title() {
        return `Processo Judicial`;
    };

    warrantyType: "JudiciaryProcess";
    number?: string;
    court?: string;
    valueOfCause?: string;
    claimant?: string;
    claimed?: string;
    typeOfProcess?: string;
}

export class DefaultWarranty {

    constructor() {
        this.warrantyType = "Warranty";
    }
    warrantyType: string;
}

export interface WarrantyItem {
    warrantyType: WarrantyType;
    description(): string;
    title(): string;
}

export function mapTitle(item: any) {
    switch (item.warrantyType) {
        case "JudiciaryProcess":
            return `Processo Judicial`;
        case "HomeEquity":
            return `Imóvel`;
        case "Vehicle":
            return `Veículo`;
        case "Consortium":
            return `Consórcio`;
        default:
            return "Não identificado";
    }
}


export class PublicPayroll
    implements WarrantyItem {

    constructor(obj?: VehicleWarranty) {
        this.warrantyType = "PublicPayroll";

        if (obj) {
            Object.assign(this, obj);
        }
    };
    title() {
        return "Consignado Público";
    }
    description() {
        return `XPTO`;
    };

    warrantyType: "PublicPayroll";
    payrollAgreementId?: string;
    employeeCode?: string;
    agreementCode?: string;
    employeerInstCode?: string;
    warrantyCode?: string;
    paymentTransferDay?: string;
    paymentAmountInCents?: string;
    employmentStatusCode?: string;
    employmentStatusDescription?: string;
}

export interface WarrantyFullType {
    warrantyType: string
    renavam: string
    typeOfVehicle: string
    chassis: string
    board: string
    supplier: string
    taxNumberOfSupplier: string
    brand: string
    color: string
    model: string
    yearOfManufacture: number
    yearOfModel: number
    typeOfFuel: string
    registryOffice: string
    registrationNumber: string
    width: number
    length: number
    addressName: string
    zipCodeAddress: string
    city: string
    uf: string
    district: string
    addressNumber: string
    complementAddress: string
    propertyFeatures: string
    number: string
    court: string
    valueOfCause: number
    claimant: string
    claimed: string
    typeOfProcess: string
    downPayment: number
    sellerCompanyName: string
    sellerRegistrationNumber: string
    valueInCash: number
    subtotalAmount: number
    totalAmountFinancedTaxFree: number
    register: string
    type: string
    splitMethodType: string
    paymentNetworks: string[]
    constituted: boolean
    totalValue: number
    employeeCode: string
    agreementCode: string
    employeerInstCode: string
    payrollAgreementId: string
    payrollAgreementName: string
    warrantyCode: string
    paymentTransferDay: number
    paymentAmountInCents: number
    employmentStatusCode: string
    employmentStatusDescription: string
}

export class InvoiceFactoring
    implements WarrantyItem {

    constructor(obj?: VehicleWarranty) {
        this.warrantyType = "InvoiceFactoring";

        if (obj) {
            Object.assign(this, obj);
        }
    };
    title() {
        return "Consignado Público";
    }
    description() {
        return `XPTO`;
    };

    warrantyType: "InvoiceFactoring";
    PayerName?: string
    PayerRegistrationNumber?: string
    ContractNumber?: string
    InvoiceNumber?: string
    InvoiceValue?: string
    DiscountValue?: string
    PresentValue?: string
    DueDate?: string
    paymentAmountInCents?: string
}


export class Consortium
    implements WarrantyItem {

    constructor(obj?: VehicleWarranty) {
        this.warrantyType = "Consortium";

        if (obj) {
            Object.assign(this, obj);
        }
    };
    title() {
        return "Consórcio";
    }
    description() {
        return `XPTO`;
    };

    warrantyType: "Consortium";
    administratorName?: string
    administratorRegistrationNumber?: string
    quota?: string
    group?: string
}