import { Box, Grid, Stack } from '@mui/material'
import { Typography } from '@uy3/web-components'
import { AutocompleteOptionList } from 'components/Forms/Autocomplete'
import { CheckboxFormField, DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields'
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField'
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField'
import { creditNoteSelectFilterOptions } from 'contexts/creditNote/creditNoteOptions'
import { useFormContext } from 'contexts/formContext'
import { useTenant } from 'contexts/tenantContext'
import { ammTypeOptions } from 'pages/Product/CreditProduct/CreditProductForm/Tabs/InformationTabCreditProduct'
import React from 'react'

type IncludeBatchAssignmentProps = {
    fundAutocomplete: AutocompleteOptionList
}

export const IncludeBatchAssignment: React.FC<IncludeBatchAssignmentProps> = ({ fundAutocomplete }) => {
    const { watch } = useFormContext();
    const { isRootTenancy } = useTenant();

    return (
        <Stack spacing={4} mt={3}>
            <Box>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Informações
                </Typography>

                <Stack direction="column" mt={2}>
                    <Grid container columns={10} spacing={3}>
                        <Grid item xs={5}>
                            <AutocompleteField
                                name="fundId"
                                label="Fundo Cessionário"
                                displayName="fundIdDisplay"
                                link={`/cadastro/fundos/${watch('fundId')}`}
                                showEndIcon={watch('fundId')}
                                endIconType="link"
                                {...fundAutocomplete!}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <CurrencyFormField
                                variant='outlined'
                                label='Taxa mínima'
                                name='minAssignmentCalculationResultValue'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <DatePickerFormField
                                label='Data da cessão'
                                name='assignmentDate'
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={5}>
                            <CurrencyFormField
                                variant='outlined'
                                label='Valor máximo da cessão em lotes'
                                name='maxBatchAssignmentValue'
                                fullWidth
                            />
                        </Grid>

                    </Grid>
                </Stack>
            </Box>
            <Box>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Operação
                </Typography>

                <Stack direction="column" mt={2}>
                    <Grid container columns={10} spacing={3}>
                        <Grid item xs={5}>
                            <SelectFormField
                                label='Status'
                                name='status'
                                multiple
                                options={creditNoteSelectFilterOptions}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <SelectFormField
                                fullWidth
                                multiple
                                label="Tipo de cálculo"
                                name="amortizationTypes"
                                options={ammTypeOptions}
                            />
                        </Grid>
                        <Grid item xs={2.5}>
                            <CurrencyFormField
                                variant='outlined'
                                label='Valor mínimo da operação'
                                name='minCreditNoteOpValue'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2.5}>
                            <CurrencyFormField
                                variant='outlined'
                                label='Valor máximo da operação'
                                name='maxCreditNoteOpValue'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2.5}>
                            <DatePickerFormField
                                label='Data de início da operação'
                                name='creditNoteOpInitialDate'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2.5}>
                            <DatePickerFormField
                                label='Data de final da operação'
                                name='creditNoteOpFinalDate'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2.5}>
                            <TextFormField
                                label='Número da operação inicial'
                                name='initialCreditNoteNoToExclude'
                                variant='outlined'
                                fullWidth
                                type='number'
                            />
                        </Grid>
                        <Grid item xs={2.5}>
                            <TextFormField
                                label='Número da operação final'
                                name='finalCreditNoteNoToExclude'
                                variant='outlined'
                                fullWidth
                                type='number'
                            />
                        </Grid>

                    </Grid>
                </Stack>
            </Box>

            {isRootTenancy && <CheckboxFormField label='Operações sem fundo atribuído' name='withoutFund' />}
        </Stack>
    )
}
