import { Drawer } from '@uy3/web-components';
import { FormProvider, useFormContext } from 'contexts/formContext';
import { usePersonsList } from 'contexts/personContext';
import { useState } from 'react';
import { InvolvedBankAccountProductForm } from './InvolvedBankAccountProductForm';
import {
    BankAccountProductFormSchema,
    defaultValuesInvolvedBankAccountProduct,
    validationSchemaInvolvedBankAccountProductForm,
} from './InvolvedSchema';
import { InvolvedBankAccountProductList } from './InvolvedBankAccountProductList';
import { ToastType, showSuccessToast, toastState } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { InvolvedBankAccountProductHeaderList } from './InvolvedBankAccountProductHeaderList';
import { useParams } from 'react-router';
import { useUserPermissionData } from 'contexts/userContext';
import {
    hasCustomPermission,
    isActionAllowed,
} from 'helpers/methods/GenericMethods';
import { useOperatorList } from 'contexts/OperatorContext';
import { useTenant } from 'contexts/tenantContext';

const filters = {
    page: 0,
    size: 10,
};

export const InvolvedBankAccountProductContainer = () => {
    const { productId } = useParams();
    const { hasPermission } = useUserPermissionData();
    const [currentPersonIndex, setCurrentPersonIndex] = useState<number | undefined>();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { setValue, watch, readOnly } = useFormContext();
    const relatedPerson = watch('relatedPerson') ?? [];
    const [selectedTenant, setSelectedTenant] = useState<string | undefined>();
    const { operatorsAutoCompleteProps } = useOperatorList(filters, 'always', selectedTenant);
    const { personAutoCompleteProps } = usePersonsList(filters, 'always');
    const { isRootTenancy } = useTenant();

    const involvedSchema = validationSchemaInvolvedBankAccountProductForm();
    const defaultValuesInvolved = defaultValuesInvolvedBankAccountProduct;

    const closeDrawer = () => setCurrentPersonIndex(undefined);

    const handleAddRelatedPerson = (indexNumber: number | undefined) =>
        setCurrentPersonIndex(indexNumber ?? relatedPerson?.length);

    const setRelatedPerson = (values: BankAccountProductFormSchema) => {
        let discriminator = personAutoCompleteProps.listOptions.filter(
            (item: any) => item.label === values?.personIdDisplay
        )[0]?.discriminator;
        let newRelatedPerson = [...relatedPerson];
        newRelatedPerson[currentPersonIndex!] = { discriminator, ...values };
        setValue('relatedPerson', newRelatedPerson);
        showSuccessToast('Lista de Envovidos atualizada com sucesso.', '', setToast);
        closeDrawer();
    };

    const onDeleteRelatedPerson = (index: number) => {
        let rowData = [...relatedPerson];
        rowData?.splice(index!, 1);
        setValue('relatedPerson', rowData);
        showSuccessToast('Envovido excluído com sucesso.', '', setToast);
    };

    const typePermission = productId === 'novo' ? 'Create' : 'Update';
    const resource = 'BankAccountProduct';
    const permissionCustom = hasCustomPermission(resource, typePermission, hasPermission);
    const enableAction = isActionAllowed(Boolean(readOnly), permissionCustom, isRootTenancy);

    const defaultFormValue = relatedPerson[currentPersonIndex!] ?? defaultValuesInvolved;
    const currentInvolved = relatedPerson[currentPersonIndex!];

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <InvolvedBankAccountProductHeaderList
                enableActionAddInvolved={enableAction}
                handlerAddInvolved={() => handleAddRelatedPerson(relatedPerson?.length)}
            />

            <InvolvedBankAccountProductList
                onDeleteRelatedPerson={onDeleteRelatedPerson}
                enableActionAddInvolved={enableAction}
                openDrawer={(indexNumber: number | undefined) =>
                    setCurrentPersonIndex(indexNumber ?? relatedPerson?.length)
                }
                name="relatedPerson"
            />

            <Drawer
                anchor="right"
                title="Adicionar envolvido"
                open={currentPersonIndex !== undefined}
                onClose={closeDrawer}
            >
                <FormProvider
                    validationSchema={involvedSchema}
                    defaultValues={defaultFormValue}
                    onSubmit={setRelatedPerson}
                    onChangeField={[{
                        fieldName: 'tenantName',
                        delegate: (value: string) => setSelectedTenant(value)
                    }]}
                >
                    <InvolvedBankAccountProductForm
                        onClose={closeDrawer}
                        personList={personAutoCompleteProps}
                        listOperator={operatorsAutoCompleteProps}
                        currentInvolved={currentInvolved}
                    />
                </FormProvider>
            </Drawer>
        </>
    );
};
