import { Grid, IconButton, Stack } from '@mui/material';
import { DataTable, EditIcon, DeleteIcon, SearchIcon } from '@uy3/web-components';
import { useFormContext } from 'contexts/formContext';
import { GridColDef } from '@mui/x-data-grid';
import { RelatedPersonCreditNote } from 'services/creditNote/types/creditNoteReadModel';
import { Link, useLocation } from 'react-router-dom';
import { iconSx } from 'contexts/apiRequestContext';
import { useUserPermissionData } from 'contexts/userContext';
import { GenericListHeader } from 'components/GenericListHeader/GenericListHeader';
import { hasCustomPermission, isActionAllowed } from 'helpers/methods/GenericMethods';
import { useCreditNoteActions } from 'contexts/creditNote/creditNoteActions';
import { useTenant } from 'contexts/tenantContext';

type InvolvedProps = {
  name: string;
  rowsPerPage: number;
  setRowsPerPage: (page: number) => void;
  setPage: (page: number) => void;
  setSelectedInvolvedIndex: (rowIndex: number | undefined) => void;
  setOpenDrawer: (open: boolean) => void;
  page: number;
  onDelete: (rowIndex: number) => void;
  readOnly: boolean;
  setHasSignatories: React.Dispatch<React.SetStateAction<boolean>>;
};

const InvolvedList = ({
  name,
  rowsPerPage,
  setRowsPerPage,
  setPage,
  setSelectedInvolvedIndex,
  page,
  onDelete,
  readOnly,
  setOpenDrawer,
  setHasSignatories,
}: InvolvedProps) => {
  const { watch } = useFormContext();
  const { pathname } = useLocation();
  const typePermission =
    pathname?.includes('novo') || pathname?.includes('nova') ? 'Create' : 'Update';
  const { hasPermission } = useUserPermissionData();
  const data = watch(name) ?? [];
  const { status } = useCreditNoteActions();
  const { isRootTenancy } = useTenant();

  // Verifica se o botão de adicionar envolvido deve ser exibido de acordo com o status da nota de crédito
  const showBtnInvolved = ['', 'Draft', 'Error', 'Disapproved', 'Revision'].includes(status!);

  const RELATED_PERSON_CREDIT_NOTE = 'relatedPersonCreditNote';
  const resource = name === RELATED_PERSON_CREDIT_NOTE ? 'CreditNote' : 'CreditProduct';
  const permissionCustom = hasCustomPermission(resource, typePermission, hasPermission);

  const actionAllowed = isActionAllowed(Boolean(readOnly), permissionCustom, isRootTenancy);

  // Menu Operação de crédito, o botão de adicionar envolvido deve ser exibido de acordo com o btn 'Salvar'
  const canEnableCreditNoteButton = permissionCustom && showBtnInvolved && !readOnly;

  // Menu Produto de crédito, o botão de adicionar envolvido deve ser exibido de acordo com o btn 'Salvar'
  const canEnableCreditProductButton = permissionCustom && actionAllowed;

  const isActionEnabled = canEnableCreditNoteButton || canEnableCreditProductButton;

  const queryData = data?.map((relatedPerson: RelatedPersonCreditNote) => {
    return {
      ...relatedPerson,
      id: Math.random().toString(),
    };
  });

  const columns: GridColDef[] = [
    {
      field: 'personIdDisplay',
      headerName: 'Nome',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
    },
    {
      field: 'typeOfRelationshipDisplay',
      headerName: 'Tipo de relação',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
    },
    {
      field: 'relatedToIdDisplay',
      headerName: 'Relacionada a',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
    },
    {
      field: 'signatureTypeDisplay',
      headerName: 'Forma de Envio',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => cellValues.value,
    },
    {
      field: 'signatureValidationDisplay',
      headerName: 'Forma de Validação',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => cellValues.value,
    },
    {
      field: 'actions',
      headerName: 'Ações',
      headerAlign: 'center',
      align: 'center',
      hideSortIcons: true,
      flex: 2,
      editable: false,
      renderCell: ({ row, id }) => {
        const rowIndex = queryData.findIndex(
          (rp: RelatedPersonCreditNote) => id === rp?.id
        );
        let personId = row?.personId;
        const pathToRedirect =
          row?.discriminator === 'LegalPerson'
            ? `/pessoas-juridicas/todas/${personId}`
            : `/pessoas-fisicas/todas/${personId}`;

        const handlerEdit = () => {
          setHasSignatories(false);
          setSelectedInvolvedIndex(rowIndex);
        };

        return (
          <Stack
            key={personId}
            component={Grid}
            direction={'row'}
            justifyContent="center"
            alignItems="center"
          >
            {isActionEnabled ? (
              <>
                <Link to={pathToRedirect}>
                  <IconButton>
                    <SearchIcon sx={iconSx} />
                  </IconButton>
                </Link>

                <IconButton onClick={handlerEdit}>
                  <EditIcon sx={iconSx} />
                </IconButton>

                <IconButton onClick={() => onDelete(rowIndex)}>
                  <DeleteIcon sx={iconSx} />
                </IconButton>
              </>
            ) : (
              <Link to={pathToRedirect}>
                <IconButton>
                  <SearchIcon sx={iconSx} />
                </IconButton>
              </Link>
            )}
          </Stack>
        );
      },
    },
  ];

  const columnsInvolved =
    name === 'relatedPersonCreditNote'
      ? columns
      : columns.filter((item) => item.field !== 'relatedToIdDisplay');

  const handlerAddInvolved = () => {
    setOpenDrawer(true);
    setHasSignatories(false);
  };

  return (
    <>
      <GenericListHeader
        title="Envolvidos"
        titleButton="Adicionar envolvido"
        enableAction={isActionEnabled}
        onClick={handlerAddInvolved}
      />
      <DataTable
        NoRowsOverlayNew="Nenhum envolvido cadastrado"
        NoResultsOverlayNew="Nenhum envolvido cadastrado"
        columns={columnsInvolved}
        rows={queryData}
        page={page}
        rowCount={queryData.totalItems}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
};

export default InvolvedList;
