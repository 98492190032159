import React from 'react';
import CreditNoteList from '../CreditNoteList';
import { FilterProvider } from 'contexts/filterContext';
import { creditNoteSelectFilterOptions } from 'contexts/creditNote/creditNoteOptions';
import {
    GridCallbackDetails,
    GridRowId,
    GridSelectionModel,
    GridSortModel,
} from '@mui/x-data-grid';
import { EnumItem } from 'contexts/enumContext';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { useFilterValues } from 'contexts/filterValuesContext';
import { categoryNameOptions } from 'services/creditProduct/types/creditProductReadModel';
import { liquidationModeOptions } from 'services/creditNote/options';

interface CreditNoteListWithFiltersProps {
    groupAutocomplete: any;
    personAutocompleteOptions: any;
    optionsTenantList: any;
    rowsPerPage: number;
    page: number;
    setRowData: (value: any) => void;
    setOrderBy: React.Dispatch<React.SetStateAction<GridSortModel>>;
    creditData: any;
    selectedStatusOfTable: string[] | GridRowId[] | undefined;
    setSelectedStatusOfTable: React.Dispatch<
        React.SetStateAction<string[] | GridRowId[] | undefined>
    >;
    handleSelectionModelChange: (
        selectionModel: GridSelectionModel,
        details: GridCallbackDetails<any>
    ) => void;
    onChangeRowsPerPage: (page: number) => void;
    onChangePage: (page: number) => void;
    typeOfRelationship: EnumItem[] | undefined
    isLoading: boolean
}

const CreditNoteListWithFilters: React.FC<CreditNoteListWithFiltersProps> = ({
    rowsPerPage,
    page,
    setRowData,
    setOrderBy,
    creditData,
    selectedStatusOfTable,
    setSelectedStatusOfTable,
    handleSelectionModelChange,
    onChangeRowsPerPage,
    onChangePage,
    groupAutocomplete,
    personAutocompleteOptions,
    optionsTenantList,
    typeOfRelationship, 
    isLoading
}) => {
    const { filterValues } = useFilterValues();
    const runSkeleton = isLoading && Object?.values(filterValues.filters ?? {}).length === 0 && selectedStatusOfTable?.length === 0;

    const defaultYesOrNo = [
        { label: "Sim", value: "true" },
        { label: "Não", value: "false" }
    ]

    return (
        <FilterProvider
            availableFilters={{
                searchString: { label: 'Todos', type: 'text', multiple: false },
                filterByOwnerGroup: {
                    label: 'Grupo',
                    type: 'autocomplete',
                    multiple: false,
                    options: groupAutocomplete?.listOptions,
                },
                liquidationMode: {
                    label: 'Modo de liquidação',
                    type: 'select',
                    multiple: true,
                    options: liquidationModeOptions,
                },
                status: {
                    label: 'Status',
                    type: 'select',
                    multiple: true,
                    options: creditNoteSelectFilterOptions,
                },
                productCategoryDisplay: {
                    label: 'Categoria do Produto',
                    type: 'select',
                    multiple: false,
                    options: categoryNameOptions
                },
                personId: {
                    label: 'Tomador',
                    type: 'autocomplete',
                    multiple: false,
                    options: personAutocompleteOptions?.listOptions,
                },
                initialDate: {
                    label: 'Data de início',
                    type: 'dateRange',
                    multiple: false,
                    dateRangeName: 'finalDate',
                },
                initialPaymentDate: {
                    label: 'Data de pagamento',
                    type: 'dateRange',
                    multiple: false,
                    dateRangeName: 'finalPaymentDate',
                },
                maxValue: { label: 'Valor máximo', type: 'text', multiple: false },
                minValue: { label: 'Valor mínimo', type: 'text', multiple: false },
                tenant: {
                    label: 'Correspondente',
                    type: 'autocomplete',
                    multiple: false,
                    options: optionsTenantList,
                },
                creditNoteNo: {
                    label: 'Número da operação',
                    type: 'text',
                    multiple: false,
                },
                relatedPersons: {
                    label: 'Envolvido',
                    type: 'autocomplete',
                    multiple: true,
                    options: personAutocompleteOptions?.listOptions,
                },
                typeOfRelationship: {
                    label: 'Tipo de relação do envolvido',
                    type: 'select',
                    multiple: true,
                    options: typeOfRelationship
                },
                ownCompliance: {
                    label: 'Aprovação compliance própria',
                    type: 'select',
                    multiple: false, 
                    options: defaultYesOrNo
                },
                ownLiquidation: {
                    label: 'Tipo de relação do envolvido',
                    type: 'select',
                    multiple: false, 
                    options: defaultYesOrNo
                },
            }}
        >
            <ListSkeleton isLoading={runSkeleton}>
                <CreditNoteList
                    rowsSelected={selectedStatusOfTable}
                    setRowsSelected={setSelectedStatusOfTable}
                    handleSelectionModelChange={handleSelectionModelChange}
                    queryData={creditData}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={onChangeRowsPerPage}
                    setPage={onChangePage}
                    page={page}
                    setOpenPopup={setRowData}
                    setOrderBy={setOrderBy}
                />
            </ListSkeleton>
        </FilterProvider>
    );
};

export default CreditNoteListWithFilters;
