import { Grid, Box, Stack, IconButton } from '@mui/material';
import { Typography, Alert, TabsProps, Timeline, InfoIcon } from '@uy3/web-components';
import { FormMode, useFormContext } from 'contexts/formContext';
import { TabBar } from 'components/TabBar/TabBar';
import NotSavedChangesAlert from 'components/NotSavedChangesAlert';
import { useAppConfig } from 'contexts/appConfig';
import { TimelineType, iconSx } from 'contexts/apiRequestContext';
import ButtonsOfActions from './ButtonsOfActions';
import { PermissionModel } from 'services/permissions';
import { ComplianceChecklistMessage } from 'components/ComplianceChecklistMessage/ComplianceChecklistMessage';
import { useComplianceChecklistVerifyPersonId } from 'contexts/creditNote/complianceCheckListContext';
import { checkActionsPerms } from 'services/permissions';
import { validationInformation } from 'helpers/formats/StringFormats';
import { TooltipComponent } from 'components/Tooltip/Tooltop';
import { InfoBankAccountRequest } from './InfoBankAccountRequest';
import { FieldValues } from 'react-hook-form';
import moment from 'moment';
import { useTenant } from 'contexts/tenantContext';


type BankAccountType = {
    bankAccountId: string;
    mode: FormMode;
    permissionsData: PermissionModel[] | undefined;
    submitApproval: () => void;
    deleteCheckList: () => void;
    onDelete: () => void;
    onChangeStatus: () => void;
    onApprove: () => void;
    onDisapprove: () => void;
    refetch: () => void;
    onSendApprovalRevision: () => void;
    openBankAccountRequest: () => void;
    onUploadDocs: (uploads: []) => void;
    onAssignBankAccountRequest: () => void;
    timeline: TimelineType[];
    statusDisplay: string;
    onApproveInstrument: () => void;
    onRejectInstrument: () => void; 
    onApproveSignature: () => void;
    onDisapproveSignature: () => void;
    cancelBankAccountRequest: () => void;
    onDraftpreview: () => void;
};

const BankAccountForm = ({
    bankAccountId,
    mode,
    permissionsData,
    submitApproval,
    deleteCheckList,
    onDelete,
    onChangeStatus,
    onUploadDocs,
    onApprove,
    refetch,
    onDisapprove,
    onSendApprovalRevision,
    openBankAccountRequest,
    onAssignBankAccountRequest,
    timeline,
    statusDisplay,
    onApproveInstrument,
    onRejectInstrument, 
    cancelBankAccountRequest,
    onApproveSignature, 
    onDisapproveSignature, 
    onDraftpreview
}: BankAccountType) => {
    const { validationErrors, isDirty, watch, getValues } = useFormContext();
    const { appConfig } = useAppConfig();
    const ownerId = watch('ownerId')
    const { data: complianceChecklist } = useComplianceChecklistVerifyPersonId(ownerId!);
    const isNew = mode === 'create';
    const { isRootTenancy } = useTenant();
    const riskTab = !!appConfig.RISK_TAB;
    const createdBy = watch('createdBy.userIdDisplay');
    const createdAtFormated = `Criado em ${moment(watch('createdAt')).format('DD/MM/YYYY')} ${createdBy ? ` por ${createdBy}` : ''}`;
    const statusToShowTabOperators = watch('status') !== 'Draft';

    const hasActionPermission = (action: string, resource: string): boolean => {
        if (checkActionsPerms(action, permissionsData, resource)) return true;
        return false;
    };

    const showBankAccounOperatorsTab = !isNew && isRootTenancy && hasActionPermission('ViewOperators', 'BankAccount') && statusToShowTabOperators;

    const tabs = [
        { label: 'Informações', href: bankAccountId },
        { label: 'Envolvidos', href: 'envolvidos' },
        { label: 'Documentos', href: 'documentos' },
        ...(showBankAccounOperatorsTab ? [{ label: 'Acessos concedidos', href: 'acessos-concedidos' }] : []),
        ...(!isNew && isRootTenancy && riskTab ? [{ label: 'KYC', href: 'kyc' }] : []),
        ...(!isNew ? [{ label: 'Histórico', href: 'historico' }] : []),
    ] as TabsProps[];

    const steps: { [key: string]: string } = {
        'Ativa': 'Encerrado',
        'Aprovação Abertura de Conta Bancária': 'Aprovação',
        'Abertura de Conta Bancária': 'Abertura',
        Encerrado: 'Ativa',
    };

    const ownerDisplayValidationInformation = (): string => {
        const ownerDisplay = validationInformation(watch('ownerDisplay'));
        if (ownerDisplay.length > 25) {
            return `${ownerDisplay.split(" ").slice(0, 4).join().replaceAll(',', ' ')}`
        }
        return ownerDisplay;
    }

    const bankAccountNumber = watch('bankAccountNumber') ?? 'N/D';
    return (
        <>
            {validationErrors && (
                <Box my={2}>
                    <Alert
                        severity="error"
                        text={
                            'Não foi possível salvar as alterações. Corrija os erros do formulário e tente novamente.'
                        }
                        variant="filled"
                    />
                </Box>
            )}
            <Box mb={2} mr={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={8}>
                        <Stack direction='row' alignItems='center'>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: '700',
                                    fontSize: '32px',
                                    lineHeight: '38px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                {isNew ? 'Novo cadastro' : ownerDisplayValidationInformation()}
                            </Typography>
                            <TooltipComponent placement='right' title={<InfoBankAccountRequest formValues={getValues() as FieldValues} />}>
                                <IconButton>
                                    <InfoIcon sx={iconSx} />
                                </IconButton>
                            </TooltipComponent>
                        </Stack>
                        {!isNew && (
                            <Grid lineHeight='2rem' spacing={2}>
                                <Typography color="neutral.medium" variant="xs">
                                    Status: {watch('statusDisplay') ?? ""}
                                </Typography>
                                <br />
                                <Typography color="neutral.medium" variant="xs">
                                    {createdAtFormated}
                                </Typography>
                                <br />
                                {statusDisplay === 'Ativa' && <Typography color="neutral.medium" variant="xs">
                                    {`Número da conta: ${bankAccountNumber}`}
                                </Typography>}
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <ButtonsOfActions
                            isNew={isNew}
                            onUploadDocs={onUploadDocs}
                            deleteCheckList={deleteCheckList}
                            submitApproval={submitApproval}
                            onDelete={onDelete}
                            onApprove={onApprove}
                            onDisapprove={onDisapprove}
                            onChangeStatus={onChangeStatus}
                            refetch={refetch}
                            onSendApprovalRevision={onSendApprovalRevision}
                            openBankAccountRequest={openBankAccountRequest}
                            onAssignBankAccountRequest={onAssignBankAccountRequest}
                            complianceChecklist={complianceChecklist}
                            onApproveInstrument={onApproveInstrument}
                            onRejectInstrument={onRejectInstrument}
                            onApproveSignature={onApproveSignature}
                            onDisapproveSignature={onDisapproveSignature}
                            onDraftpreview={onDraftpreview}
                            cancelBankAccountRequest={cancelBankAccountRequest}
                        />
                    </Grid>
                </Stack>
                {isDirty && <Grid mt={2}>
                    <NotSavedChangesAlert isDirty={isDirty} />
                </Grid>}
                {!isNew && (
                    <Grid spacing={2}>
                        <Grid mt={4} ml={-2}>
                            <Timeline
                                status={steps[statusDisplay!] ?? statusDisplay!}
                                statusDisplay={statusDisplay!}
                                timeline={timeline}
                                timelineType='bankAccountRequest'
                            />
                        </Grid>
                        {complianceChecklist?.length > 0 &&
                            <Grid spacing={2} mt={2} mr={3} mb={2}>
                                <ComplianceChecklistMessage
                                    complianceChecklistData={complianceChecklist}
                                    name={watch('name')}
                                />
                            </Grid>}
                    </Grid>
                )}

                <Grid sx={{ mt: 6 }}>
                    <TabBar tabs={tabs} />
                </Grid>
            </Box>
        </>
    );
};

export default BankAccountForm;
