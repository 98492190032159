import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { ApiResponseError, invalidateAndRefetch, useApiRequest } from 'contexts/apiRequestContext';
import { useIdentity } from 'contexts/identityContext';
import { handleErrorUseQuery } from 'helpers/methods/handleErrorUseQuery';
import { FieldValues } from 'react-hook-form';
import { deletePersonsAccountById, saveOrUpdateBankAccountAsync } from 'services/person';

interface ManageBankAccountMutationProps {
    recordType: string;
    personId: string;
    onSuccess: (data: any) => void;
    onError: (error: ApiResponseError) => void;
}

export function usePersonsBankAccountMutation({
    recordType,
    personId,
    onSuccess,
    onError,
}: ManageBankAccountMutationProps) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const queryClient = useQueryClient();

    const { mutateAsync, isLoading } = useMutation({
        mutationFn: async (values: FieldValues) => {
            startRequest();
            try {
                const bankAccountId = values?.id;

                const { data } = await saveOrUpdateBankAccountAsync(
                    recordType,
                    values,
                    personId,
                    token!,
                    bankAccountId
                );

                return data;
            } catch (error) {
                if (isAxiosError(error)) {
                    setSubmitError({
                        type: 'error',
                        code: error?.response?.status + '' + error?.response?.statusText,
                        message: error?.message,
                        errors: error?.response?.data?.errors,
                    });
                }
                throw error;
            } finally {
                endRequest(true);
            }
        },
        onSuccess(data, _, context) {
            invalidateAndRefetch(queryClient, ['get-naturalPerson-data', personId]);
            invalidateAndRefetch(queryClient, ['legalPerson-data', personId]);
            onSuccess && onSuccess(data);
        },
        onError(error, _, context) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });

    return { mutateAsync, isLoading };
}

interface DeleteBankAccountMutationProps {
    recordType: string;
    personId: string;
    onSuccess: (data: any) => void;
    onError: (error: ApiResponseError) => void;
}

export function useDeleteBankAccountMutation({
    recordType,
    personId,
    onSuccess,
    onError,
}: DeleteBankAccountMutationProps) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading } = useMutation({
        mutationFn: async (values: FieldValues): Promise<void> => {
            startRequest();
            try {
                const bankAccountId = values?.id;
                await deletePersonsAccountById(recordType, personId, bankAccountId, token!);
                endRequest(true);
            } catch (error) {
                endRequest(false);
                if (isAxiosError(error)) {
                    setSubmitError({
                        type: 'error',
                        code: error.response?.status + '' + error.response?.statusText,
                        message: error.message,
                        errors: error.response?.data?.errors,
                    });
                }
                throw error;
            }
        },
        onSuccess(data, _, context) {
            onSuccess && onSuccess(data);
        },
        onError(error, _, context) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });

    return { mutateAsyncDelete: mutateAsync, isLoading };
}
