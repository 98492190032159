import { Grid, Stack } from '@mui/material';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { SelectFormField } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { useAppConfig } from 'contexts/appConfig';
import { useFormContext } from 'contexts/formContext';
import { useTenant } from 'contexts/tenantContext';
import { getFieldOptions } from 'helpers/methods/formFieldConfiguration';
import { CreditNoteReadModel } from 'services/creditNote/types/creditNoteReadModel';

type CreditNoteCnabProps = {
    fundAutoCompleteProps: AutocompleteOptionList;
    creditNotesSelected: CreditNoteReadModel[];
};

export const CnabCreditNoteForm = ({ fundAutoCompleteProps, creditNotesSelected }: CreditNoteCnabProps) => {
    const { appConfig } = useAppConfig();
    const formFieldsConfig = appConfig?.FORM_FIELDS;
    const { isRootTenancy } = useTenant();
    const { watch } = useFormContext();
    const optionsByConfig = getFieldOptions("cnabReports", formFieldsConfig); 
    const hasOptionsCnabReportsConfig: boolean = !!optionsByConfig && optionsByConfig?.length > 0;
    const isCommercialPapper: boolean = creditNotesSelected.length === 1 && creditNotesSelected[0]?.productInstrumentType?.toLowerCase() === 'commercialpapper';

    let defaultOptions = [
        { label: 'CNAB 400', value: 'Cnab400' },
        { label: 'CNAB 400 - Itaú', value: 'Cnab400itau' },
        { label: 'CNAB 400B', value: 'Cnab400B' },
        { label: 'CNAB 444', value: 'Cnab444' },
        { label: 'CNAB 444 - REAG', value: 'Cnab444REAG' },
        { label: 'CNAB 500', value: 'Cnab500' },
        { label: 'CNAB 550', value: 'Cnab550' }, 
        { label: 'CNAB 600', value: 'Cnab600' }, 
    ];
    
    const options = hasOptionsCnabReportsConfig ? optionsByConfig : defaultOptions; 

    if (isRootTenancy && !hasOptionsCnabReportsConfig) {
        options?.push(
            { label: 'Relatório de Liquidação', value: 'liquidationFile' },
            { label: 'Remessa Vortx', value: 'Vortx' }, 
            { label: 'CNAB AXA', value: 'CnabAXA' },
            { label: 'CNAB AXA (Cancelar)', value: 'CnabAXACancel' }
        );
    };

    if (isCommercialPapper && !hasOptionsCnabReportsConfig) {
        options?.push({ label: 'CNAB B3 - Nota Comercial', value: 'NCB3' });
    }

    const cnabValue = watch('cnab');
    const isCnabValid = ['Cnab444REAG', 'Cnab444', 'Cnab500', 'Cnab550', 'Cnab600'].includes(cnabValue);

    return (
        <Stack spacing={3}>
            <Grid item xs={12}>
                <SelectFormField name="cnab" label="Tipo de relatório" options={options} />
            </Grid>

            {isCnabValid && (
                <Grid item xs={12}>
                    <AutocompleteField
                        showEndIcon={true}
                        label="Fundo cessionário"
                        displayName="fundIdDisplay"
                        name="fundId"
                        {...fundAutoCompleteProps}
                    />
                </Grid>
            )}
        </Stack>
    );
};
