import { FormProvider } from 'contexts/formContext';
import React from 'react';
import { CancelBankAccountRequest } from './CancelBankAccountRequest';
import {
    BankAccountRequestCreateOrUpdate,
    DisapprovedBankAccountProps,
} from 'services/accounts/bankAccountRequest/bankAccountRequest.types';
import {
    cancelBankAccountDefaultValues,
    cancelBankAccountRequestValidationSchema,
} from './CancelBankAccountRequestSchema';
import { useGetNaturalOrLegalPerson } from 'contexts/personContext';
import { FieldValues } from 'react-hook-form';
import {
    ApiResponseError,
    handleOnError,
    showSuccessToast,
    ToastType,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { cancelBankAccountRequestById } from 'services/accounts/bankAccountRequest/bankAccountRequest';
import { useNavigate, useParams } from 'react-router';
import { useIdentity } from 'contexts/identityContext';
import { NaturalPersonReadModel } from 'services/accounts/naturalPerson/types/naturalPersonReadModel';

type CancelBankAccountRequestContainerProps = {
    onClose: () => void;
    bankAccountRequestData: BankAccountRequestCreateOrUpdate;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
};

export const CancelBankAccountRequestContainer = ({
    onClose,
    bankAccountRequestData,
    setToast,
}: CancelBankAccountRequestContainerProps) => {
    const { bankAccountId } = useParams();
    const navigate = useNavigate();
    const { token } = useIdentity();
    const { startRequest, endRequest } = useApiRequest();

    const { data: naturalOrLegalPerson, isLoading } = useGetNaturalOrLegalPerson(
        {
            discriminator: bankAccountRequestData?.ownerDiscriminator as
                | 'LegalPerson'
                | 'NaturalPerson',
            personId: bankAccountRequestData?.ownerId,
        }!
    );

    const onSubmit = async (values: FieldValues) => {
        startRequest();

        const payload: DisapprovedBankAccountProps = {
            blockType: values?.blockType,
            blockUntil: values?.blockUntil,
            message: values?.message,
            reasonDisapproval: values?.reasonDisapproval,
            attributeTypes: values?.complianceChecklist,
        };

        try {
            await cancelBankAccountRequestById(bankAccountId!, payload, token!);
            showSuccessToast('Sucesso ao cancelar a solicitação de conta.', '', setToast);
            onClose();
            navigate(-1);
            endRequest(true);
        } catch (error) {
            endRequest(false);
            handleOnError(error as ApiResponseError, setToast);
        }
    };

    if (isLoading) return <></>;

    return (
        <FormProvider
            {...{
                defaultValues: cancelBankAccountDefaultValues,
                validationSchema: cancelBankAccountRequestValidationSchema(),
                onSubmit,
            }}
        >
            <CancelBankAccountRequest
                {...{
                    onClose,
                    naturalOrLegalPerson: naturalOrLegalPerson as NaturalPersonReadModel,
                }}
            />
        </FormProvider>
    );
};
