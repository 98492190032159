import { FormMode, FormProvider } from 'contexts/formContext';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiResponseError, IBase, ToastType, showErrorToast, showSuccessToast, showWarningToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { RefreshProgress } from 'components/RefreshProgress';
import {
    BankAccountProductFormSchema,
    defaultValuesBankAccountProductForm,
    validationSchemaBankAccountProductForm,
} from './BankAccountProductSchema';
import BankAccountProductForm from './BankAccountProductForm';
import { useProductAccMutation, useProductAccountData } from 'contexts/bankAccountProduct';
import { createBankAccountProduct, deleteBankAccountProductById } from 'services/bankAccountProduct';
import { useIdentity } from 'contexts/identityContext';
import { useState } from 'react';
import AssignDrawer from 'components/Assign/AssignDrawer';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Button, DeleteIcon, Modal, Popup } from '@uy3/web-components';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import Toast from 'components/Toast/Toast';
import { activeTheme } from 'services/theme';
import { GenericFormSkeleton } from 'components/Skeleton/GenericFormSkeleton';
import GenericErrorBoundary from 'components/Errors/ErrorBoundary/GenericErrorBoundary';
import { useUserPermissionData } from 'contexts/userContext';
import { FieldValues } from 'react-hook-form';
import { CloneRegisterContainer } from 'components/CloneRegister/CloneRegisterContainer';
import { hasCustomPermission } from 'helpers/methods/GenericMethods';
import { BankAccountProductReadModel } from 'services/bankAccountProduct/types/productReadModel';
import { useTenant } from 'contexts/tenantContext';

const theme = activeTheme();
export const BankAccountProductFormContainer = () => {
    const { productId } = useParams();
    const navigate = useNavigate();
    const { token } = useIdentity();
    const { submitError, submitting, setSubmitError } = useApiRequest();
    const { setFormFieldsErrors } = useFormFieldsError();
    const [action, setAction] = useState<string | undefined>(undefined)
    const formMode: FormMode = productId !== 'novo' ? 'update' : 'create';
    const { productAccData, productAccErr, productAccStatus, refetch, isLoading } = useProductAccountData(productId!);
    const { hasPermission } = useUserPermissionData();
    const [toast, setToast] = useState<ToastType>(toastState);
    const onClose = () => setAction(undefined);
    const { isRootTenancy } = useTenant();

    const isReadOnly = (): boolean => {
        const resource = 'BankAccountProduct';
        const typePermission = formMode === 'create' ? 'Create' : 'Update';
        const permissionCustom = hasCustomPermission(resource, typePermission, hasPermission);

        if (!permissionCustom || !isRootTenancy) return true;
        return false;
    };

    const handleCloneBankAccountProduct = async (values: FieldValues) => {
        const payload = {
            ...productAccData,
            name: values?.keepSameName === true ? productAccData?.name : values?.name
        } as BankAccountProductFormSchema & BankAccountProductReadModel;
        return await createBankAccountProduct(payload, values?.tenant!, token!)
            .then(({ data }: any) => {
                const successTitle = "Produto clonado com sucesso";
                const descriptionSuccess = "Em aluns instantes você será redirecionado para o produto clonado."
                showSuccessToast(successTitle, descriptionSuccess, setToast);
                onClose();
                return setTimeout(() => {
                    navigate(`/cadastro/produtos-conta/${data!}`);
                    setToast({ open: false, severity: 'success', title: successTitle });
                }, 2000);
            })
            .catch((error: ApiResponseError) => {
                const { errorMessage } = mapErrorResponse(error);
                showErrorToast('Ops, ocorreu um erro ao clonar o produto', errorMessage, setToast);
            });
    };

    const handleDelete = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage, warningToastError } = mapErrorResponse(error);

        if (warningToastError && warningToastError?.length > 0) {
            const title = 'Atenção!';
            showWarningToast(title, warningToastError, setToast);
        }
        else {
            const title = 'Ops, ocorreu um erro!';
            const description = errorMessage;
            showErrorToast(title, description, setToast);
        }
    };

    const onSuccess = (data: BankAccountProductReadModel) => {
        const id = formMode === 'create' ? data : data.id ?? productId;
        navigate(`/cadastro/produtos-conta/${id}`);
        if (productId !== 'novo') {
            refetch();
        }
        setSubmitError(undefined);
        setFormFieldsErrors([]);
        const title = 'Produto de Conta salvo com sucesso!';
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        const { fieldErrors } = mapErrorResponse(error);
        setFormFieldsErrors(fieldErrors ?? []);
        setSubmitError(error);
        handleDelete(error);
    };

    const { mutateAsync } = useProductAccMutation(productId!, onSuccess, onError);
    const onSubmit = async (values: BankAccountProductFormSchema) => {
        await mutateAsync(values);
    };

    const schema = validationSchemaBankAccountProductForm();
    const defaultValues = productAccData ?? defaultValuesBankAccountProductForm;

    const onDeleteProduct = async () => {
        await deleteBankAccountProductById(productId!, token!)
            .then(() => {
                navigate(`/cadastro/produtos-conta`);
            })
            .catch((error: ApiResponseError) => handleDelete(error));
    };

    return (
        <GenericFormSkeleton isLoading={isLoading && formMode === 'update'}>
            <GenericErrorBoundary
                fallback="Produto de conta"
                error={productAccErr}
                status={productAccStatus}
            >
                <Toast toast={toast} setToast={setToast} />
                <RefreshProgress refreshing={submitting} />
                <ApiErrorAlert error={submitError} />
                <FormProvider
                    validationSchema={schema}
                    defaultValues={defaultValues}
                    onSubmit={onSubmit}
                    readOnly={isReadOnly()}
                >
                    <BankAccountProductForm
                        mode={formMode}
                        productId={productId!}
                        setAction={setAction}
                        refetch={refetch}
                        setToast={setToast}
                    />
                </FormProvider>
                <AssignDrawer
                    recordId={productId!}
                    recordType="BankAccountProduct"
                    openDrawer={action === 'assign'}
                    onClose={onClose}
                    navigate="/cadastro/produtos-conta"
                />
                <Popup
                    open={action === 'delete'}
                    onClose={onClose}
                    title="Excluir Produto"
                    text="Tem certeza que deseja excluir este produto?"
                    children={
                        <Grid2 container justifyContent="flex-end" spacing={2}>
                            <Grid2>
                                <Button
                                    variant="outlined"
                                    onClick={onClose}
                                >
                                    Cancelar
                                </Button>
                            </Grid2>
                            <Grid2>
                                <Button
                                    variant="contained"
                                    startIcon={<DeleteIcon htmlColor={theme.palette.common.white} />}
                                    onClick={onDeleteProduct}>
                                    Excluir
                                </Button>
                            </Grid2>
                        </Grid2>
                    }
                />

                <Modal
                    open={action === 'cloneProduct'}
                    align='left'
                    title='Clonar produto'
                    onClose={onClose}
                    description='Realize uma cópia do produto, com a possibilidade de manter ou alterar o nome do mesmo.'
                    children={(
                        <CloneRegisterContainer
                            data={productAccData! as IBase}
                            onClose={onClose}
                            resource='product'
                            onSubmit={handleCloneBankAccountProduct}
                        />
                    )}
                />
            </GenericErrorBoundary>
        </GenericFormSkeleton>
    );
};
