import { Stack, styled } from '@mui/material';
import { Menu,  MenuItems, MenuAccordionItems } from '@uy3/web-components';
import { useAppArea } from 'contexts/appAreaContext';
import { useAppConfig } from 'contexts/appConfig';
import { useTenant } from 'contexts/tenantContext';
import { useUserPermissionData } from 'contexts/userContext';
import { isEmpty } from 'lodash';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router';
import appRouter from 'router';
import { activeTheme } from 'services/theme';

export const StyledNav = styled('nav')(() => ({
  position: 'absolute',
  '@media print': {
    display: 'none',
  }
}));

export function IconView(img: ReactNode, width: number) {
  return (
    <Stack width={width} alignItems="center">
      {img}
    </Stack>
  );
}

const theme = activeTheme();

function SideMenu() {
  const { hasPermission, data, status } = useUserPermissionData();
  const { appConfig } = useAppConfig();
  const { setMenuWidth } = useAppArea();
  const navigate = useNavigate();
  const location = useLocation();
  const { isRootTenancy } = useTenant();

  const activeOutsideMenuConfig = appConfig?.ACTIVE_AUTO_OUTSIDE_MENU;
  const isActivateAutoOutsideMenu = activeOutsideMenuConfig !== 0;

  function reduceRoutesToMenuItem(currentMenuItems: MenuItems[], route: any): MenuItems[] {

    const active =
      location.pathname === route.path ||
      (route.path !== '/' && location.pathname?.indexOf(route.path) > -1);

    let childrenMenuItems: MenuAccordionItems[] = [];

    if (route.handle.permission !== undefined) {
      var perm = route.handle.permission;
      if (perm.length) {
        const hasPerm = perm.some((p: any) => hasPermission(p.resource, p.type));
        if (!hasPerm) {
          return [...currentMenuItems];
        }
      } else {
        const hasPerm = hasPermission(perm.resource, perm.type);
     
        if (!hasPerm) {
          return [...currentMenuItems];
        }

        if (hasPerm && perm?.hidden === true) {
          return [...currentMenuItems]
        }
      }
    }

    if (!!route?.handle?.childrenCanBeHidden) {
      const onlyChildrensPermissions = route?.children?.filter((item: any) => !isEmpty(item?.handle?.displayName))?.map((c: any) => c?.handle?.permission);

      // every option is hidden and has permission
      const allChildrensHasDisabled = onlyChildrensPermissions?.every((p: any) => {
        return p?.hidden === true && hasPermission(p?.resource, p?.type)
      });

      // at config is menu enabled, but does not have permission
      const filteredOnlyNotHidden = onlyChildrensPermissions?.filter((p: any) => p?.hidden === false)?.every((p: any) => {
        return hasPermission(p?.resource, p?.type) === false;
      });

      if (allChildrensHasDisabled) return [...currentMenuItems];

      if (!allChildrensHasDisabled && !!filteredOnlyNotHidden)
        return [...currentMenuItems];
    }

    if (route.children?.length) {
      childrenMenuItems = route.children.reduce(reduceRoutesToMenuItem, [])
        .map((item: any) => {
          return {
            ...item,
            subSubmenu:  item?.submenu ?? []
          }
        });
    }

    if (route.handle.isRootTenancy === true) {
      if (!isRootTenancy) {
        return [...currentMenuItems];
      }
    }

    if (route.handle.showNav === true) {

      return [
        ...currentMenuItems,
        {
          active: active,
          href: route.path,
          icon: route.handle.icon,
          title: route.handle.displayName,
          submenu: childrenMenuItems.length ? childrenMenuItems : undefined,
        },
      ];
    } else {
      return [...currentMenuItems, ...(childrenMenuItems || [])];
    }
  }
 
  var menuConfig = appRouter.routes.reduce(reduceRoutesToMenuItem, []);

  return (
    <StyledNav>
      <Menu
        isLoading={data === undefined || status === 'loading'}
        logoIcon={IconView(
          //@ts-ignore
          theme?.components?.Icon,
          90
        )}
        items={menuConfig}
        drawerWidthMain={setMenuWidth}
        closeDelay={100}
        onClickLogout={() => {
          navigate('login/logout');
        }}
        activateAutoOutsideMenu={isActivateAutoOutsideMenu}
      />
    </StyledNav>
  );
}

export default SideMenu;