import { Drawer } from '@uy3/web-components';
import React, { useState } from 'react';
import { FormProvider, useFormContext } from 'contexts/formContext';
import { ToastType, IUploadModel, showSuccessToast, toastState } from 'contexts/apiRequestContext';
import { EnumItem, useEnumContext } from 'contexts/enumContext';
import { RefreshProgress } from 'components/RefreshProgress';
import { defaultValuesDocForm, validationSchemaDocForm } from '../UploadForm/UploadSchema';
import UploadList from '../UploadList/UploadList';
import { UploadFormWrapper } from '../UploadForm/UploadFormWrapper';
import Toast from 'components/Toast/Toast';
import { useUserPermissionData } from 'contexts/userContext';
import { useParams } from 'react-router';
import { GenericListHeader } from 'components/GenericListHeader/GenericListHeader';
import { useAppConfig } from 'contexts/appConfig';
import { getFieldOptionsByChildren } from 'helpers/methods/formFieldConfiguration';
import { isEmpty } from 'lodash';
import { hasCustomPermission, isActionAllowed } from 'helpers/methods/GenericMethods';
import { GridValidRowModel } from '@mui/x-data-grid';
import DrawerWrapper from '../DrawerWrapper/DrawerWrapper';
import { useFundData } from 'contexts/fundContext';
import { useTenant } from 'contexts/tenantContext';
import GenericErrorBoundary from 'components/Errors/ErrorBoundary/GenericErrorBoundary';

type UploadTabContainerProps = {
    name: 'uploads' | 'relatedDocs';
    resource: 'NaturalPerson' | 'LegalPerson' | 'Fund' | 'BatchAssignment';
};

export const UploadTabContainer: React.FC<UploadTabContainerProps> = ({ name, resource }) => {
    const {
        status,
        data: typeOfDocument,
        error,
    } = useEnumContext({ size: 100, enumName: 'FileType' });
    const params = useParams();
    const { id } = params;
    const { appConfig } = useAppConfig();
    const isModeCreate = ['novo', 'nova'].includes(Object.values(params)[0] ?? '');
    const [selectedUploadIndex, setSelectedUploadIndex] = useState<number | undefined>(undefined);
    const [toast, setToast] = useState<ToastType>(toastState);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const { setValue, watch, readOnly } = useFormContext();
    const { hasPermission } = useUserPermissionData();
    const [rowData, setRowData] = useState<GridValidRowModel | undefined>();
    const [selectedAction, setSelectedAction] = useState<string | undefined>();
    const [statusSignature, setStatusSignature] = useState('');
    const fundId = watch('fundId') ?? 'novo';
    const { fundData } = useFundData(fundId!);
    const { isRootTenancy } = useTenant();

    const formFieldsConfig = appConfig?.FORM_FIELDS;
    const fieldConfigOptions = (fieldName: string, children: string) =>
        getFieldOptionsByChildren(fieldName, children, formFieldsConfig);

    const typePermission = isModeCreate ? 'Create' : 'Update';
    const permissionCustom = hasCustomPermission(resource, typePermission, hasPermission);

    const isFund = resource === 'Fund';
    const isBatchAssignment = resource === 'BatchAssignment';

    // Define se o tenant é root, considerando a validação isRootTenancy apenas para 'Fund'
    const rootTenancy = isFund ? isRootTenancy : true;

    // Define o valor efetivo de readOnly, desconsiderando para 'BatchAssignment'
    const effectiveReadOnly = isBatchAssignment ? false : Boolean(readOnly);
    const enableAction = isActionAllowed(effectiveReadOnly, permissionCustom, rootTenancy);

    const uploads = watch(name) ?? [];
    const docSchema = validationSchemaDocForm();

    const setNewUpload = (values: IUploadModel) => {
        let newUploads = uploads !== undefined ? [...uploads] : [];
        if (selectedUploadIndex === undefined) newUploads?.push(values);
        else newUploads[selectedUploadIndex!] = values;

        if (!!name) {
            setValue(name, newUploads);
            const title = 'Documento enviado com sucesso';
            const description = 'Ótimo! A lista de documentos foi atualizada.';
            showSuccessToast(title, description, setToast);

            closeDrawer();
        }
    };

    const onDelete = (index: number) => {
        let newUploads = [...uploads];
        newUploads?.splice(index, 1);
        if (watch(name)) {
            setValue(name, newUploads);
        }
    };

    const closeDrawer = () => {
        setSelectedUploadIndex(undefined);
        setOpenDrawer(false);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const getTypeOfDocuments = (): EnumItem[] => {
        const optionsDocuments = fieldConfigOptions('naturalPerson', 'typeOfDocuments');
        if (resource === 'NaturalPerson' && !isEmpty(optionsDocuments)) {
            return (optionsDocuments as EnumItem[]) ?? [];
        }
        return typeOfDocument ?? [];
    };

    const defaultFormValue =
        selectedUploadIndex !== undefined ? uploads[selectedUploadIndex!] : defaultValuesDocForm;

    return (
        <GenericErrorBoundary status={status} error={error} fallback="documentos">
            <RefreshProgress refreshing={status === 'loading'} />
            <DrawerWrapper
                {...{
                    data: fundData,
                    targetId: id!,
                    openDrawer,
                    setOpenDrawer,
                    selectedAction,
                    selectedUploadIndex,
                    setSelectedUploadIndex,
                    typeOfDocument: typeOfDocument!,
                    rowData,
                    setRowData,
                    setSelectedAction,
                    setStatusSignature,
                    setToast,
                    targetType: 'BatchAssignment',
                }}
            />

            <Toast toast={toast} setToast={setToast} />
            <GenericListHeader
                enableAction={enableAction}
                onClick={() => setOpenDrawer(true)}
                title="Documentos"
                titleButton="Adicionar documento"
            />
            <UploadList
                titleHeader="Documentos"
                enableAction={enableAction}
                uploads={uploads ?? []}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={onChangeRowsPerPage}
                setPage={onChangePage}
                setOpenDrawer={(type, row) => {
                    setSelectedAction(type);
                    setRowData(row!);
                }}
                page={page}
                setSelectedUploadIndex={(rowNumber: number | undefined) => {
                    setSelectedUploadIndex(rowNumber ?? uploads?.length);
                    setRowData(undefined);
                }}
                onDelete={onDelete}
                typeOfDocument={typeOfDocument ?? []}
                statusSignature={statusSignature}
            />
            <Drawer
                anchor="right"
                title="Enviar documentos"
                description="Confira as informações antes de enviar os documentos"
                open={selectedUploadIndex !== undefined || openDrawer}
                onClose={closeDrawer}
            >
                <FormProvider
                    validationSchema={docSchema}
                    defaultValues={defaultFormValue}
                    onSubmit={setNewUpload}
                >
                    <UploadFormWrapper
                        options={getTypeOfDocuments() ?? []}
                        accept="image/*,.pdf,.doc,.docx,.xlsx,.xsl,.rar,.zip"
                        onClose={closeDrawer}
                    />
                </FormProvider>
            </Drawer>
        </GenericErrorBoundary>
    );
};
